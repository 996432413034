:global .dashboardGridItemMarket {
  .btnGroup {
    margin-bottom: 1em;
    width: 100%;

    >.btn {
      flex: 1 1 auto;
    }
  }

  .dashboardMarketTable {
    thead tr th,
    tbody tr td {
      text-align: right;
      white-space: nowrap;

      &:first-child {
        text-align: left;
        width: 60%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-last-child(2) {
        width: 0;
        padding-right: 0;
      }
    }

    .variation {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      &:before,
      &:after {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &:after {
        content: "";
        width: var(--px8);
        height: var(--px8);
        margin-left: var(--px4);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }

      &.higher {
        color: var(--green);

        &:before {
          content: "+";
        }

        &:after {
          background-image: url("../assets/icons/icn-up.svg");
        }
      }

      &.lower {
        color: var(--red);

        &:before {
          content: "-"
        }

        &:after {
          background-image: url("../assets/icons/icn-down.svg");
        }
      }
    }

    @media(max-width: 991px)  {
      thead tr th:nth-child(3),
      tbody tr td:nth-child(3) {
        display: none;
      }

      .usdConversion {
        display: block;
      }

      .variation {
        display: inline-flex;
      }
    }
  }
}
