:global {
  .navSidebar {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: var(--px80);
    transition: max-width 0.3s ease;

    .userWalletWrapper {
      flex-direction: column;
    }

    &.isOpen {
      max-width: var(--navSidebar-max-width);

      .userWalletWrapper {
        flex-direction: unset;
      }

      .navItemsList .navLink {
        justify-content: left;
        max-width: 100%;
      }
    }

    .navSidebarWrapper {
      position: sticky;
      top: var(--px72);
      left: 0;
      display: flex;
      flex-direction: column;
      row-gap: var(--px24);
      width: 100%;
      max-width: 100%;
      min-height: 44.625em;
      padding: 1em;
      background-color: var(--dark);
      border-radius: 1em;
      box-shadow: var(--box-shadow);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .navSidebarHeader {
      display: flex;
      flex-direction: column;
      row-gap: var(--px8);
      width: 100%;
    }

    .navSidebarMenu {
      width: 100%;
      max-width: 100%;
    }
  }

  .dashboardGridItemsSectionTablePagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding-top: var(--px32);

    .btnTablePagination {
      &:disabled {
        pointer-events: none;
        cursor: default;

        svg {
          color: var(--warm-grey);
        }
      }

      &.isDragging {
        color: var(--green);
        width: 6em; //96px
        max-width: 6em;

        &:hover, &:focus, &:active, &.hover {
          color: fuchsia;
        }
      }
    }

    .tablePaginationBulletBtnList {
      flex: 1 1 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: var(--px12);
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
      }

      .btnTablePaginationBullet {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--px12);
        height: var(--px12);
        padding: 0;
        background-color: var(--purple);
        border: 0;
        border-radius: var(--px2);
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:focus {
          box-shadow: var(--focus);
        }

        &.isCurrent {
          opacity: 1;
          pointer-events: none;
          cursor: default;
        }

        &.isDragging {
          background-color: var(--green);
          width: var(--px24);
          max-width: var(--px24);

          &:hover, &:focus, &:active, &.hover {
            background-color: fuchsia;
            opacity: 1;
          }
        }
      }
    }
  }

  .notificationBadge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 4px 8px;
    font-size: 1em;
    border-radius: 50%;
    background: var(--purple);
    color: white;
  }

  .notificationBadgeUnmint {
    position: absolute;
    bottom: -10px;
    right: -10px;
    padding: 4px 8px;
    font-size: 1em;
    border-radius: 50%;
    background: #186218;
    display: flex;
  }
}
