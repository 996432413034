.btn {
  --btn-min-height: var(--px48);
  --btn-padding-x: var(--px24);
  --btn-padding-y: 0.3125em; //5px
  --btn-bg-color: var(--dark);
  --btn-border-radius: var(--px8);
  --btn-color: white;
  --btn-font-size: 1em;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--px8);
  height: auto;
  min-height: var(--btn-min-height);
  padding: var(--btn-padding-y) var(--btn-padding-x);
  background: var(--btn-bg-color);
  border: 0;
  border-radius: var(--btn-border-radius);
  color: var(--btn-color);
  font-size: var(--btn-font-size);
  appearance: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    --btn-bg-color: var(--gradient);
  }

  &:focus {
    outline: 0;
    box-shadow: inset var(--focus);
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  svg {
    flex-shrink: 0;
    max-width: 1em;
    max-height: 1em;
    transition: color 0.3s ease;
  }

  &.isFullWidth {
    width: 100%;
    max-width: 100%;
  }

  &[data-icon-placement="left"] {
    .btnIcon, svg, i.feather {
      order: -1;
    }
  }

  &[data-color="gradient"] {
    --btn-bg-color: linear-gradient(92.22deg, #0BF6CC, #073DC8, #0BF6CC);
    --btn-color: white;

    background-size: 300% 100%;
    background-position: 5% 0;
    transition: 0.3s ease, background-position 0.4s linear;

    &:hover {
      background-position: 100% 0;
    }
  }

  &[data-color="gradientOrange"] {
    --btn-bg-color: linear-gradient(92.22deg, #d9480b, #952f03, #d9480b);
    --btn-color: white;

    background-size: 300% 100%;
    background-position: 5% 0;
    transition: 0.3s ease, background-position 0.4s linear;

    &:hover {
      background-position: 100% 0;
    }
  }

  &[data-color="gradientText"] {
    --btn-bg-color: transparent;
    --btn-color: white;

    .btnWrapper {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: var(--px8);
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:hover {
      --btn-bg-color: var(--dark-medium);
    }
  }

  &[data-color="dark-medium"] {
    --btn-bg-color: var(--dark-medium);
    --btn-color: white;
  }

  &[data-variant="icon"] {
    --btn-padding-x: 0;
    --btn-padding-y: 0;
    --btn-min-height: unset;
    --btn-bg-color: transparent;
    --btn-color: inherit;
    --btn-font-size: inherit;

    width: var(--px48);
    height: var(--px48);

    &[data-color="purple"] {
      --btn-color: var(--purple);

      &:hover {
        --btn-color: white;
        --btn-bg-color: transparent;
      }
    }

    &[data-color="dark-medium"] {
      --btn-bg-color: var(--dark-medium);
      --btn-color: white;
    }
  }

  &[data-variant="link"] {
    --btn-min-height: unset;
    --btn-padding-x: 0;
    --btn-padding-y: 0;
    --btn-bg-color: transparent;
    --btn-border-radius: unset;
    --btn-color: inherit;
    --btn-font-size: inherit;

    &.matchBtnPadding {
      --btn-padding-x: var(--px24);
    }

    &[data-color="gradient"],
    &[data-color="gradientText"] {
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &:hover {
        background: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &[data-size="sm"] {
      --btn-min-height: unset;
    }
  }

  &[data-size="sm"] {
    --btn-min-height: 3em; //36px
    --btn-font-size: var(--px12);
    gap: 0.666em; //8px
  }

  &.btnMenu {
    --btn-menu-icon-width: var(--px12);
    --btn-menu-icon-height: 0.09375em; //1.5px

    &:hover {
      --btn-bg-color: transparent;
    }

    &.hasBadge {
      position: relative;

      .badge {
        position: absolute;
        top: 0;
        right: -0.3125em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--px14);
        height: var(--px14);
        background: var(--red);
        border-radius: 100%;
        color: white;

        .badgeIcon {
          font-size: var(--px8);
        }
      }
    }

    .btnMenuIcon {
      position: relative;
      width: var(--btn-menu-icon-width);
      height: var(--btn-menu-icon-height);
      background-color: currentColor;
      transition: all 0.3s ease;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: var(--btn-menu-icon-width);
        height: var(--btn-menu-icon-height);
        background-color: inherit;
        transition: all 0.3s ease;
      }

      &:before {
        top: -0.25em; //-4px
      }

      &:after {
        bottom: -0.25em; //-4px
      }
    }

    &.isOpen {
      .btnMenuIcon {
        background-color: transparent;

        &:before,
        &:after {
          background-color: white;
        }

        &:before {
          top: 50%;
          left: 50%;
          transform: translate(-50%) rotate(45deg);
        }

        &:after {
          top: 50%;
          bottom: unset;
          left: 50%;
          transform: translate(-50%) rotate(-45deg);
        }
      }
    }
  }
}
