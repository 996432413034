:global .customSelect {
  &.hiddenChevron {
    .customSelect__indicator.customSelect__dropdown-indicator {
      display: none;
    }
  }

  .customSelect__control {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: var(--px48);
    padding: 0 1em;
    background-color: var(--dark-medium);
    border: var(--px1) solid var(--dark-medium);
    border-radius: var(--px8);
    color: var(--body-font-color);
    outline: 0 !important;
    transition: all 0.3s ease;
    box-sizing: border-box;
    cursor: default;

    &:hover {
      border-color: var(--dark-medium);
    }

    &.customSelect__control--is-focused {
      box-shadow: inset var(--focus);
    }

    &.customSelect__control--menu-is-open {
      .customSelect__indicator.customSelect__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  .customSelect__value-container {
    flex: 1;
    position: relative;
    display: grid;
    align-items: center;
    -webkit-box-align: center;
    flex-wrap: wrap;
    padding: 0 calc(var(--px8) / 2); //0 4px
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;

    &.customSelect__value-container--has-value {
      .customSelect__single-value .customSelectOptionFlexbox .customSelectOptionSuffix {
        display: none;
      }
    }
  }

  .customSelect__input-container {
    color: inherit;
    padding: 0;
  }

  .customSelect__placeholder {
    color: var(--warm-grey);
  }

  .customSelect__indicator-separator {
    display: none;
    align-self: stretch;
    width: 1px;
    margin-bottom: var(--px8);
    margin-top: var(--px8);
    background-color: var(--warm-grey);
    box-sizing: border-box;
  }

  .customSelect__indicator {
    display: flex;
    width: 1em;
    height: 1em;
    color: var(--warm-grey);
    padding: 0;
    transition: all 0.3s ease;
    box-sizing: border-box;

    svg {
      max-width: 1em;
      max-height: 1em;
    }

    &.customSelect__clear-indicator {
      color: var(--purple);

      &:hover {
        color: var(--body-font-color);
      }
    }
  }

  .customSelect__single-value {
    grid-area: 1/1/2/3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
  }

  .customSelect__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    width: 100%;
    margin-bottom: var(--px8);
    margin-top: var(--px8);
    background-color: var(--dark-medium);
    border-radius: var(--px8);
    border: 0;
    //box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
    box-shadow:
      var(--px-8) var(--px8) var(--px8) var(--body-bg-color),
      var(--px8) var(--px8) var(--px8) var(--body-bg-color);
    overflow: hidden;
    box-sizing: border-box;
  }

  .customSelect__menu-list {
    position: relative;
    max-height: 18em; //288px
    padding: 1em;
    background-color: inherit;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }

  .customSelect__option {
    display: block;
    width: 100%;
    padding: 1em;
    background-color: transparent;
    border-radius: var(--px8);
    color: inherit;
    font-size: inherit;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: var(--purple);
    box-sizing: border-box;
    transition: 0.3s ease;

    &.customSelect__option--is-focused {
      background-color: var(--body-bg-color);
    }

    &.customSelect__option--is-selected {
      background-color: var(--body-bg-color);
    }
  }

  .customSelectOptionPrefix {
    flex-shrink: 0;
    line-height: 1;
    svg, img {
      max-width: var(--px24);
      max-height: var(--px24);
    }
  }

  .customSelect__menu-notice {
    text-align: left;
  }

}
