:global .about {
  --section-spacer: 7.5em;
  position: relative;

  @media(max-width: 991px) {
    --section-spacer: var(--px48);
  }

  .aboutBgWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url("../assets/images/about-bg-png-x2.webp");
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: 150% 50%;

    @media (max-width: 991px) {
      height: 50vh;
      background-size: cover;
      background-position: 20% 0;
    }
  }

  .aboutPageWrapper {
    width: 100%;
    max-width: 75em; //1200px
    margin: 0 auto;
  }

  .aboutSectionHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    min-height: 100vh;

    .aboutSectionHeaderTextWrapper {
      width: 100%;
      max-width: 50%;
    }

    .heading {
      margin-bottom: 0;
      color: var(--warm-grey);
      font-weight: 200;
    }

    @media (max-width: 991px) {
      .aboutSectionHeaderTextWrapper {
        max-width: 100%;
      }
    }
  }

  .display64px {
    font-size: var(--px64);
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: 991px) {
      font-size: var(--px48);
    }
  }

  .display36px {
    margin-bottom: 2em;
    font-size: var(--px36);
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: 991px)  {
      font-size: var(--px32);
      margin-bottom: 0.75em;
    }
  }

  .aboutGrid {
    display: grid;
    align-items: center;
    gap: var(--px48);

    &.gridTwo {
      grid-template-columns: 1fr 1fr;
    }

    &.gridThree {
      align-items: stretch;
      grid-template-columns: repeat(3, 1fr);
    }

    .aboutGridText {
      .text18 {
        font-size: 1.125em;
        font-weight: 200;
      }
    }

    .aboutGridImage {
      display: block;

      > img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--px24);
    }
  }

  .aboutSectionDetails {
    position: relative;
    margin-bottom: var(--section-spacer);

    .gradient {
      display: block;
      position: absolute;
      top: -25%;
      right: 0;
      z-index: -1;
      width: 100%;
      max-width: 75%;
      object-fit: contain;
    }

    .aboutGrid.gridTwo {
      grid-template-columns: 1fr 0.75fr;

      &.primary {
        margin-bottom: var(--section-spacer);

        .text18 {
          max-width: 75%;
        }

        @media(max-width: 991px) {
          .text18 {
            max-width: 100%;
          }
        }
      }

      &.secondary {
        ul {
          font-size: 1.125em;
          display: flex;
          flex-direction: column;
          row-gap: 1em;
          margin: 0;
          padding-left: 0;
          list-style: none;

          >li {
            display: flex;
            align-items: center;
            gap: 1em;

            &:before {
              content: "\e83f";
              color: fuchsia;
              font-size: 1.5em;
              //noinspection CssNoGenericFontName
              font-family: "feather" !important;
            }
          }
        }
      }
    }

    @media(max-width: 991px) {
      .gradient {
        top: 50%;
        max-width: 100%;
        transform: translate(-50%);
      }
    }
  }

  .aboutSectionFeatures {
    margin-bottom: var(--section-spacer);

    h2 {
      text-align: center;
    }
  }

  .aboutSectionFeatureCard {
    padding: var(--px48) var(--px24);
    text-align: center;

    h3 {
      font-size: var(--px24);
      font-weight: 900;
    }

    p {
      font-size: var(--px18);
      font-weight: 200;
    }
  }

  .aboutSectionRoadmap{
    margin-bottom: var(--section-spacer);

    h2 {
      text-align: center;
    }

    .aboutRoadmapList {
      --circle-size: 3em;
      --spacing: 0.5em;
      display: flex;
      width: 100%;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;

        &:before {
          --size: 3em;
          content: "";
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--circle-size);
          height: var(--circle-size);
          border-radius: 50%;
          background: radial-gradient(circle at 65% 15%, white 1px, fuchsia 3%, #800080 60%, fuchsia 100%);
          //box-shadow:0 0 20px fuchsia, inset 0 0 10px pink;
          margin: 0 auto 1em;
        }

        &:not(:last-child) {
          &:after {
            content: "";
            position: relative;
            top: calc(var(--circle-size) / 2);
            left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
            width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
            height: 0.125em;
            background: linear-gradient(90deg, rgba(0, 237, 0, 0.9) 70%, transparent 0) repeat-x;
            background-size: 0.5em 0.125em; //8px 2px
            background-position: 0 0, 0 100%;
            order: -1;
            animation: linearGradientMove .3s infinite linear;
          }

          // &:not(.checked) {
          //   &:after {
          //     content: "";
          //     position: absolute;
          //     top: calc(var(--circle-size) / 2);
          //     left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
          //     width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
          //     height: 0.125em;
          //     background: linear-gradient(90deg, rgb(255, 255, 255) 35%, transparent 0) repeat-x;
          //     background-size: 0.5em 0.125em; //8px 2px
          //     background-position: 0 0, 0 100%;
          //     //order: -1;
          //     animation: linearGradientMove .3s infinite linear;
          //   }
          // }
        }


        &.checked {
          &:before {
            content: "\e83f";
            font-family: "feather" !important;
            background: radial-gradient(circle at 65% 15%, white 1px, aqua 3%, darkblue 60%, aqua 100%);
            box-shadow: none;
          }

          h3 {
            color: white;
            text-shadow: 0 0 var(--px24) white;
          }
          p {
            color: white;
            text-shadow: 0 0 var(--px24) white;
          }
        }

        h3 {
          margin-bottom: 0;
          font-weight: 900;
          color: var(--warm-grey);
        }
        p {
          font-size: var(--px14);
          color: var(--warm-grey);
          text-transform: uppercase;
          letter-spacing: 0.08em;
        }
      }
    }

    @media (max-width: 991px) {
      text-align: center;

      .aboutRoadmapList {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        width: unset;

        li {
          position: relative;
          flex: unset;
          display: inline-flex;
          flex-direction: row;
          gap: 1em;
          height: 8em;
          text-align: left;

          &:before {
            position: static;
          }

          &:not(:last-child) {
            position: relative;

            &:after {
              position: absolute;
              z-index: -1;
              top: calc(var(--circle-size) + var(--spacing));
              left: calc(var(--circle-size) / 2);
              width: 0.125em;
              height: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
              background: linear-gradient(0deg, white 50%, transparent 0);
              background-repeat: repeat-y;
              background-size: 0.125em 0.5em;
              background-position: 0 0, 100% 0;
              animation: linearGradientMoveVertical .3s infinite linear;
            }
          }
        }
      }
    }
  }

  .aboutSectionPartners{
    margin-bottom: var(--section-spacer);

    h2 {
      margin-bottom: 2em;
      text-align: center;
    }

    .aboutGrid.gridThree {
      max-width: 50em;
      margin: 0 auto;
    }

    .imageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 3/2;
      padding: var(--px24);

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
      }
    }

    @media (max-width: 991px)  {
      .aboutGrid.gridThree {
        display: grid;
        align-items: stretch;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
      }
    }

    @media (max-width: 767px)  {
      .aboutGrid.gridThree {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
