:global {
  .notificationBadgeMyPortfolioUnmint {
    position: absolute;
    bottom: -10px;
    right: -10px;
    padding: 4px 4px;
    font-size: 1em;
    border-radius: 50%;
    background: var(--purple);
    display: flex;
  }

  .notificationBadgeMyPortfolioUnmintFilter{
    position: absolute;
    right: 5px;
    padding: 4px 4px;
    font-size: 1em;
    border-radius: 50%;
    background: var(--purple);
    display: flex;
  }
}
