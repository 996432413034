:global .logo {
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  transition: 0.3s ease;

  .logoImage {
    flex: 0 0 var(--px48);
    display: inline-flex;
    width: var(--px48);
    height: var(--px48);
    border-radius: 100%;
    overflow: hidden;
    transition: 0.3s ease;
  }

  .logoMonogram {
    flex-shrink: 0;
    width: 5.625em; //90px
    transition: 0.3s ease;
    line-height: 1;
  }

  .logoMonogram g {
    fill: white;
  }
}
