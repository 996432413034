:global {
  .userWallet {
    display: flex;
    flex-direction: column;
    row-gap: var(--px8);
    width: 100%;


    .userWalletWrapper {
      display: flex;
      align-items: center;
      gap: var(--px8);
      width: 100%;
    }

    .userWalletAvatar {
      flex: 0 0 var(--px36);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--px36);
      height: var(--px36);
      border-radius: 100%;
      background: linear-gradient(89.52deg, #0BF6CC -79.06%, #073DC8 99.17%), #FFE2D6;

      &.isClickable {
        position: relative;
        cursor: pointer;
      }
      
      &.isClickableWithRed {
        position: relative;
        color: var(--red);
        cursor: pointer;

        &:hover {

        }

        .badge {
          position: absolute;
          top: 0;
          right: -0.3125em;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--px14);
          height: var(--px14);
          background: var(--red);
          border-radius: 100%;
          color: white;

          .badgeIcon {
            font-size: var(--px8);
          }
        }
      }

      svg {
        max-width: 50%;
        max-height: 50%;
      }
    }

    .userWalletInfo {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      cursor: pointer;
    }

    .userWalletName,
    .userWalletId {
      display: flex;
      font-size: var(--px12);

      & > span { 
        max-width: fit-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > span:first-of-type{
        flex: 1;
      }
      @media (min-width: 992px) {
        & > span:last-of-type{
          margin-left: var(--px-8);
        }
      }
    }

    .userWalletId {
      color: rgba(255, 255, 255, 0.6);
    }

    .btnDisconnectWallet {
      flex: 0 0 var(--px48);
    }

    .btnFaucet,
    .btnCollectRewards,
    .btnConnectWallet {
      --btn-padding-x: 1em;
      overflow: hidden;
      white-space: nowrap;

      >svg {
        flex-shrink: 0;
      }
    }

    .btnFaucet,
    .btnCollectRewards {
      line-height: var(--btn-font-size);
    }

    .btnFaucet {
      .faucetIcon {
        flex: 0 0 2em;
        width: 2em;
        height: 2em;

        > svg {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .btnNotifications {
      .bellIcon {
        flex: 0 0 2em;
        width: 1.5em;
        height: 1.5em;

        > svg {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }




    .btnCollectRewards {
      .astrovaultTokenIcon {
        flex: 0 0 2em;
        width: 2em;
        height: 2em;

        > svg {
          max-width: 70%;
          max-height: 70%;
        }
      }
    }
  }
  .btnLink {
    .linkIcon {
      flex: 0 0 2em;
      width: 1.25em;
      height: 1.25em;

      > svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
