:global {
  .footerNavItemsList {
    display: inline-flex;
    align-items: stretch;
    flex-wrap: nowrap;
    gap: var(--px12);
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: var(--px8);
      background-color: var(--body-bg-color);
      border-radius: var(--px8);
      box-shadow: var(--box-shadow);
      gap: var(--px8);

      &:first-child {
        a {
          display: inline-flex;
          align-items: center;
          /*width: 4.3125em; //69px*/
        }
      }

      &.social {
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 1em;
          height: 1em;
        }
      }

      &.utilities {
        padding: 0;
      }

      a {
        transition: all 0.3s ease;
        z-index: 15;

        &:hover,
        &:focus {
          color: var(--purple);

          g{fill:var(--purple)}
        }
      }

      .footerNavItemsListText {
        color: var(--warm-grey);
        white-space: nowrap;
        font-size: 0.8em;
      }

      .logo-aws svg .txt {
        fill: #ddd;
      }

      .logo-aws svg {
        max-height: 1em;
        height: 1em;
        vertical-align: middle;
      }
    }
}


}
