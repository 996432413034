:global {
  .sectionMobileMenu {
    position: relative;
    z-index: 1;
    display: inline-block;
    //padding-right: 1em;

    .btnMenu {
      position: relative;
      z-index: 1;
      background-color: var(--dark);
    }

    .mobileMenu {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      height: auto;
      max-height: 100vh;
      transform: translateY(100%);
      transition: 0.3s ease;
      overflow: hidden;

      &.menuIsOpen {
        transform: translateY(0);
      }
    }

    .mobileMenuWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding: 0 var(--px8);
    }

    .mobileMenuInner {
      display: flex;
      flex-direction: column;
      height: auto;
      max-height: 100vh;
      padding-bottom: var(--px80);
      background-color: var(--dark);
      border-top: var(--border);
      border-left: var(--border);
      border-right: var(--border);
      border-radius: 1em 1em 0 0;

      .mobileMenuHeader {
        padding: var(--px24) 1em var(--px12);
      }

      .logoImage {
        flex: 0 0 var(--px64);
        width: var(--px64);
        height: var(--px64);
      }

      .logoMonogram {
        width: 5.021875em; //80.35px
      }

      .mobileMenuContent {
        display: flex;
        flex-direction: column;
        row-gap: var(--px24);
        height: auto;
        max-height: 22.125em; //354px
        padding: var(--px12) 1em 0;
        overflow-y: auto;

        > *:last-child {
          padding-bottom: var(--px48);
        }
      }

      .navItemsList {
        padding-bottom: var(--px24);
        border-bottom: var(--px1) solid;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-image-source: linear-gradient(92.22deg, #0BF6CC 0.58%, rgba(7, 61, 200, 0) 100%);
        border-image-slice: 1;

        .navLink {
          justify-content: flex-start;
        }
      }

      .footerNavItemsList {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        >li {
          background-color: var(--body-bg-color);

          .footerNavItemsListText {
            font-size: var(--px12);
          }
        }
      }

      .footerUtilitiesMenuList.primary {
        padding: var(--px24) 0;
        border-bottom: var(--px1) solid;
        border-top: var(--px1) solid;
        border-left: 0;
        border-right: 0;
        border-image-source: linear-gradient(92.22deg, #0BF6CC 0.58%, rgba(7, 61, 200, 0) 100%);
        border-image-slice: 1;
      }
    }
  }
}
