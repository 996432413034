:global .errorPageLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 9.6875em var(--px24) var(--px24);
  background: url("../assets/images/trade-bg.webp") no-repeat top left fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
  background-size: 100% auto, cover;

  @media (max-width: 991px) {
    background: url("../assets/images/trade-bg.webp") no-repeat top left fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
    background-size: 100% auto, cover;
    padding: 9.6875em 1em 1em;
  }

  @media (max-width: 575px) {
    background: url("../assets/images/trade-bg-mobile-2x.webp") no-repeat top center fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(118.8% 32.05% at 23.07% 0%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center;
    background-size: 100% auto, cover;
  }

  .errorPageWrapper {
    width: 100%;
    max-width: 30em; //480px
    margin: 0 auto;
    text-align: center;
  }

  .errorPageTitle {
    margin-bottom: 0;
    font-size: 12em;
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: 575px) {
      font-size: 8em;
    }
  }

  .errorPageText {
    margin-bottom: 2em;

    @media (max-width: 575px) {
      font-size: 1em;
    }
  }

  .errorPageLogoFooter {
    margin-top: var(--px48);
  }
}
