:global .permitAuthenticationModal {
  .sectionModalHeader h1 {
    svg {
      flex-shrink: 0;
      max-width: 1em;
      max-height: 1em;
    }
  }

  .sectionModalContent {
    margin-bottom: var(--px32);
    padding-bottom: var(--px32);
    color: var(--warm-grey);

    p:last-child,
    p:only-child {
      margin-bottom: 0;
    }
  }
}
