:global .supportedChainsModal {
    .sectionModalHeader {
        margin-bottom: 1em;
    }
    .sectionModalContent {
      font-size: 1.1em;
      padding-bottom: 1em;
      padding-top: 1em;
    }

   
  }
  