:global {
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }

  .customModal {
    .customModalOverlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9998;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 100vh;
      padding: var(--px24);
      background-color: rgba(8, 8, 25, 0.7);
      opacity: 0;
      overflow-x: hidden;
      overflow-y: auto;
      transition: opacity 0.4s ease;

      &.ReactModal__Overlay--after-open {
        opacity: 1;

        .customModalContent {
          transform: translateY(0);
          opacity: 1;
        }
      }

      &.ReactModal__Overlay--before-close {
        opacity: 0;

        .customModalContent {
          transform: translateY(-10%);
          opacity: 0;
        }
      }

      @media (max-width: 991px) {
        padding: 1em;
      }
    }

    .customModalContent {
      display: block;
      width: 100%;
      margin: auto;
      transform: translateY(-10%);
      opacity: 0;
      background-color: transparent;
      transition: transform 0.3s ease, opacity 0.4s ease;

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &.xl {
        max-width: 100%; //fullsize
      }

      &.lg {
        max-width: 71.25em; //1140px
      }

      &.md {
        max-width: 50em; //800px
      }

      &.sm {
        max-width: 37.1875em; //595px
      }
    }

    &.left,
    &.right {
      .customModalOverlay {
        display: block;
        padding: 0;
      }

      .customModalContent {
        transform: none !important;
        height: 100%;
        background-color: var(--dark);
      }

      .customModalWrapper {
        height: auto !important;
        min-height: 100vh;
        border-radius: 0;
        border: 0;
      }
    }

    &.right {
      .customModalContent {
        margin: 0 0 0 auto !important;
      }

      .customModalWrapper {
        margin: 0 !important;
        transform: translateY(0) translateX(100%);
        transition: 0.3s ease;
      }

      .customModalOverlay {
        &.ReactModal__Overlay--after-open .customModalWrapper {
          transform: translateY(0) translateX(0);
          opacity: 1;
        }

        &.ReactModal__Overlay--before-close {
          opacity: 0;

          .customModalWrapper {
            transform: translateY(0) translateX(100%);
            opacity: 0;
            transition: 0.3s ease;
          }
        }
      }
    }

    &.left {
      .customModalContent {
        margin: 0 auto 0 0 !important;
      }

      .customModalWrapper {
        margin: 0 !important;
        transform: translateY(0) translateX(-100%);
        transition: 0.3s ease;
      }

      .customModalOverlay {
        &.ReactModal__Overlay--after-open .customModalWrapper {
          transform: translateY(0) translateX(0);
          opacity: 1;
        }

        &.ReactModal__Overlay--before-close {
          opacity: 0;

          .customModalWrapper {
            transform: translateY(0) translateX(-100%);
            opacity: 0;
            transition: 0.3s ease;
          }
        }
      }
    }

    .customModalWrapper {
      position: relative;
      width: 100%;
      height: auto;
      margin: auto;
      padding: var(--px48);
      background-color: var(--dark);
      border: 0;
      border-radius: 1em;

      @media (max-width: 991px) {
        padding: calc(var(--px48) + var(--px24)) 1em var(--px48);
      }
    }

    .btnModalClose {
      position: absolute;
      top: var(--px24);
      right: var(--px24);
      z-index: 1;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      @media (max-width: 991px) {
        top: var(--px12);
        right: var(--px12);
      }
    }

    .sectionModalHeader {
      margin-bottom: var(--px32);

      >*:last-child,
      >*:only-child {
        margin-bottom: 0;
      }
    }
  }

  .outbidGuide {
    .customModalBody {
      ul.spaced {
        margin-block-end: 0;
        margin-bottom: 0;
        > li {
          padding-bottom: 1em;
        }
      }

      div.spaced {
          padding-bottom: 1em;
      }

      .strike {
        text-decoration: line-through
      }
    }
  }
}
