.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  table-layout: auto;

  thead th {
    padding: 1em var(--px8);
    border-bottom: var(--border);
    color: var(--warm-grey);
    font-weight: 300;
    text-align: left;

    &:first-child {
      padding-left: 0;
    }
  }

  tbody tr.isDragging {
    position: absolute !important;
    left: auto !important;
    top: auto !important;
    display: table;
    table-layout: auto;
    backdrop-filter: blur(5px);

    td {
      display: table-cell;
    }
  }

  tbody td {
    padding: 1em var(--px8);
    border-bottom: var(--border);

    &:first-child {
      padding-left: 0;
    }
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }

  .tableCta {
    width: 0;

    .btn.dragHandle {
      position: relative;
      padding: 0.5em;

      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .tokenIcon {
    flex-shrink: 0;
    display: inline-flex;
    width: var(--px40);
    height: var(--px40);
  }

  .tableInputValueConversion {
    color: var(--warm-grey);
    font-size: var(--px12);
    font-weight: 200;
    text-align: right;

    &.withMarginTop {
      margin-top: var(--px8);
    }
  }

  &.withGradientBorderRows {
    border-collapse: collapse;
    border-spacing: 0;

    th, td {
      border-bottom: 0;
    }

    tbody tr {
      background: linear-gradient(92.22deg, #0BF6CC 0.58%, rgba(7, 61, 200, 0) 100%) no-repeat bottom center;
      background-size: 100% var(--px1);
    }

    // safari only
    @media not all and (min-resolution:.001dpcm) {

      tbody tr {
        background: none;
        border-bottom: var(--border);
      }
    }
  }

  &.isResponsive {

    @media (max-width: 991px) {
      thead, tbody, th, td {
        display: block;
        text-align: left !important;
      }

      th, td {
        padding: 0 !important;
      }

      thead tr,
      tbody tr:not(.gradientRow) {
        display: grid;
        gap: var(--px8);
      }

      thead tr {
        grid-template-columns: repeat(3, 1fr);
      }

      tbody tr:not(.gradientRow) {
        padding: 1em 0;
      }
    }
  }
}

