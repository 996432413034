@use "_normalize.css";
@use "_variables.scss";
@use "_buttons.scss";
@use "_btnGroup.scss";
@use "_forms.scss";
@use "_tables.scss";
@use "_customSwiper.scss";
@use "_customReactToastify.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--body-bg-color);
  color: var(--body-font-color);
  font-size: var(--body-font-size);
  font-family: var(--body-font-family);
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.03em;

  &.mobileMenuIsOpen {
    overflow: hidden;

    .dashboardPage {
      opacity: 0.4;
    }
  }

  &:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
  }
}

//TYPOGRAPHY
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 400;
  letter-spacing: -0.03em;
  color: white;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: -0.03em;
}

h1, .h1 {
  font-size: var(--px24);
  line-height: 1.33em; //32px
}

h2, .h2 {
  font-size: var(--px24);
  line-height: 1.33em; //32px
}

h3, .h3 {
  font-size: var(--px22);
  line-height: 1.33em; //32px
}

h4, .h4 {
  font-size: var(--px20);
}

h5, .h5 {
  font-size: var(--px18);
}

h6, .h6 {
  font-size: 1em;
}

a {
  color: inherit;
  text-decoration: none;
}

p, ul, ol {
  margin-top: 0;
}

i, i.feather {
  font-size: 1em;
  transition: 0.3s ease;
}

svg {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: 0.3s ease;
}

//HELPERS

.btn.dragHandle,
.dragHandle {
  cursor: grab;
  overflow: hidden;
  padding: 0.15em;

  &:focus,
  &:focus-within {
    box-shadow: inset var(--focus);
  }

  &:active {
    cursor: grabbing;
  }

  svg {
    max-width: var(--px48);
    max-height: var(--px48);
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: var(--px8);

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }
}

.visuallyHidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.flexbox {
  display: flex;
  align-items: center;
  gap: var(--px8);
}

.inlineFlexbox {
  display: inline-flex;
  align-items: center;
  gap: var(--px8);
}

.textGrey {
  color: var(--warm-grey);
}

.textRed {
  color: var(--red);
}

.fillRed svg g {
  fill: var(--red);
}

.fillRed svg path {
  fill: var(--red);
}

.textOrange {
  color: var(--orange);
}

.textFuchsia {
  color: fuchsia;
}

.textGradient {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.withGradientBorderBottom,
.withGradientBorderTop {
  //this way is safari compatible
  border-left: 0;
  border-right: 0;
  border-image-source: linear-gradient(92.22deg, #0BF6CC 0.58%, rgba(7, 61, 200, 0) 100%);
  border-image-slice: 1;
}

.withGradientBorderTop {
  border-bottom: 0;
  border-top: var(--px1) solid;
}

.withGradientBorderBottom {
  border-bottom: var(--px1) solid;
  border-top: 0;
}

.withGradientBorder {
  position: relative;
  padding: var(--px24);
  border-radius: 1em;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: var(--px1) solid transparent;
    background: var(--dark-blue-gradient) border-box;
    border-radius: inherit;
    mask:
      linear-gradient(to right, black 0, black 100%) content-box,
      linear-gradient(to right, black 0, black 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media(max-width: 991px) {
    padding: 1em;
  }
}

.tokenGridList {
  position: relative;
  z-index: 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: var(--px30);
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  padding: 0 0 1em;
  margin: 0 0 1em;
  list-style: none;
  overflow-x: auto;

  > li {
    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        z-index: $i;
      }
    }

    span {
      display: block;
      width: var(--px40);
      height: var(--px40);
      background-color: var(--dark-medium);
      border-radius: 100%;

      svg, img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.selectTokenSubFieldset {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: var(--border);

  >.flexbox {
    position: relative;
    z-index: 1;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;

    >.formGroupSelect {
      flex-shrink: 0;

      .customSelect {
        position: static;

        .customSelect__control {
          border: 0;
          background-color: transparent;
        }

        .customSelectOptionSuffix {
          margin-left: auto;
          text-align: right;
          color: var(--warm-grey);
          font-size: var(--px12);
        }
      }
    }

    >.formGroup:not(.formGroupSelect) {
      flex: 0 1 21.75em; //348px

      >input {
        text-align: right;
      }
    }
  }

  .selectTokenSubFieldsetHelpText {
    display: flex;
    justify-content: space-between;
  }
}

.selectTokenBalanceAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--px8);
}


@keyframes loaderAnimation {
  0% {
    transform: rotate(0deg);
    filter: hue-rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    filter: hue-rotate(360deg);
  }
}

@keyframes linearGradientMove {
  100% {
    background-position: 0.5em 0, -0.5em 100%;
  }
}

@keyframes linearGradientMoveVertical {
  100% {
    background-position: 0 0.5em, 100% -0.5em;
  }
}

@keyframes outbidTextMoving {
  0% {
    transform: translateX(0%);
    right: -10%;
    //left: -10%;
  }
  25% {
    transform: translateX(0%);
    right: 0;
    //left: 0%;
  }
  75%,
  100% {
    transform: translateX(-100%);
    right: 100%;
    //left: 100%;
  }
}

@keyframes statusPulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.075, 1.075);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes outbitActivityBonusRowBackgroundFade {
  from {
    background-color: #d9480b;
  }

  to {
    background-color: rgba(255,255,255,0.1);
  }
}

@keyframes outbitActivityTopBidderRowBackgroundFade {
  from {
    background-color: #073DC8;
  }

  to {
    background-color: rgba(255,255,255,0.1);
  }
}
