:global .dashboardPage.dashboard {
  main {
    @media (min-width: 992px) {
      padding-bottom: 9em; //144px
      padding-left: var(--px64);
    }
  }

  .seviceStatus{
    .warning{
      color: darkorange;
    }
    margin: 1em 1em 1em 0;
  }

  .dashboardSectionGrid {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .dashboardSectionGridButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: var(--px8);

    .ghostDragHandle {
      opacity: 0;
      pointer-events: none;
    }
  }

  .highcharts-container {
    .highcharts-tooltip-box {
        fill: black;
        fill-opacity: 0.6;
        stroke-width: 0;
    }

    .highcharts-tooltip text {
        fill: #bbb !important;
        text-shadow: 0 0 3px black;
    }

    .highcharts-label text {
      fill: #aaa !important;
    }
  }

  .sectionGrid {
    --gap-x: var(--px48);
    --gap-y: var(--px48);

    display: flex;
    align-items: stretch;
    gap: var(--gap-x);
    width: 100%;
    max-width: 100%;
    height: 100%;

    .sectionGridColumn {
      width: 100%;
      max-width: calc(50% - var(--gap-x) / 2);
    }

    .sectionGridColumnItem {
      display: flex;
      flex-direction: column;
      row-gap: var(--gap-y);
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    // temporary fix to fit outside handle
    .sectionGridItem {
      position: relative;

      &.gridItemIsDragging {
        backdrop-filter: blur(20px);
      }

      .dashboardGridHandle {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    @media (min-width: 1680px) {
      --gap-x: 6.25em; //100px
      --gap-y: var(--px64);
    }

    @media(max-width: 1199px) {
      --gap-x: var(--px24);
      --gap-y: var(--px48);
    }

    @media (max-width: 991px) {
      --gap-x: var(--px24);
      --gap-y: var(--px48);
      display: block;

      .sectionGridColumn {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .dashboardMenuDropdown {
    .customDropdownContentWrapper {
      display: flex;
      flex-direction: column;
      row-gap: var(--px8);
    }

    .btnGroup.vertical .btn {
      --btn-padding-x: 1em;
    }

    .formGroupSelect {
      border-top: var(--border);
      padding-top: var(--px8);

      .customSelect {
        max-width: 7.5em; //120px
      }
    }
  }

  .dashboardGridItemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--px8);
    margin-bottom: 1em;

    h2 {
      margin-bottom: 0;
    }
  }

  .dashboardGridItemSectionTable {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .loader{
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .gridContainer {
      display: grid;

      .stackedItems {
        grid-row: 1;
        grid-column: 1;
      }
    }

    .makeTransparent {
      visibility: hidden;
      opacity: 0;
    }

    .table {
      thead tr th,
      tbody tr td {
        vertical-align: middle;
      }

      .flexbox {
        display: inline-flex;
        gap: var(--px8);

        .flexboxItem {
          display: inline-flex;
          align-items: center;
          gap: var(--px8);
        }

        .tokenIcon {
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--px40);
          height: var(--px40);

          svg, img {
            width: 100%;
            max-width: 100%;
            height: auto;
            object-fit: contain;
          }
        }

        .tokenInfo {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;

          >.tokenName {
            font-size: var(--px14);
            color: var(--warm-grey);
          }
        }
      }

      .buttonContainer {
        display: inline-flex;
      }
    }
  }

  .tokenIconPortfolio {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--px34);
    height: var(--px34);
    margin: .50rem;

    svg, img {
      width: 100%;
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .tokenInfo {

    >.tokenAmounts  {
     inline-size: max-content;
    }
    >.tokenName {
      font-size: var(--px14);
      color: var(--warm-grey);
    }
  }

  .dashboardGridItemsSectionTablePagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding-top: var(--px32);

    .btnTablePagination {
      &:disabled {
        pointer-events: none;
        cursor: default;

        svg {
          color: var(--warm-grey);
        }
      }

      &.isDragging {
        color: var(--green);
        width: 6em; //96px
        max-width: 6em;

        &:hover, &:focus, &:active, &.hover {
          color: fuchsia;
        }
      }
    }

    .tablePaginationBulletBtnList {
      flex: 1 1 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: var(--px12);
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
      }

      .btnTablePaginationBullet {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--px12);
        height: var(--px12);
        padding: 0;
        background-color: var(--purple);
        border: 0;
        border-radius: var(--px2);
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:focus {
          box-shadow: var(--focus);
        }

        &.isCurrent {
          opacity: 1;
          pointer-events: none;
          cursor: default;
        }

        &.isDragging {
          background-color: var(--green);
          width: var(--px24);
          max-width: var(--px24);

          &:hover, &:focus, &:active, &.hover {
            background-color: fuchsia;
            opacity: 1;
          }
        }
      }
    }
  }

  .horizontalSlider {
    width: 100%;
    max-width: 100%;
    height: var(--px24);
    margin: var(--px8) 0;

    .horizontalSliderThumb {
      position: absolute;
      top: 50%;
      z-index: 1;
      height: 100%;
      transform: translateY(-50%);
      cursor: pointer;

      .thumbNowValue,
      .thumbHandle {
        display: inline-flex;
        align-items: center;
      }

      .thumbNowValue {
        position: absolute;
        left: 50%;
        bottom: 100%;
        justify-content: center;
        padding: 0.5em;
        font-size: var(--px12);
        transform: translateX(-50%);
      }

      .thumbHandle {
        width: var(--px8);
        height: var(--px24);
        background-color: var(--dark);
        border: 1px solid var(--warm-grey);
        border-radius: 0;
      }
    }

    .horizontalSliderTrack {
      top: 50%;
      z-index: 0;
      height: var(--px8);
      border: 1px solid var(--warm-grey);
      border-radius: 1em;
      background-color: transparent;
      transform: translateY(-50%);

      &.horizontalSliderTrack-0 {
        background: var(--gradient);
        background-color: unset;
        border: 0;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .grvt8Container {
    //display: flex;
    //justify-content: space-between;

    .grvt8Representation {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--px12);
      .burnGRVT8Input {
        text-align: left;
        width: 100%;
      }
    }

    .grvt8Burn {
      display: flex;
      margin-top:0.75em;
      padding: 0.5em;
      align-items: center;
    }
    .buttonContainer {
      align-self: center;
      .grvt8BurnButton {
        width: 100%;
        max-width: 100%;
        font-size: 1.2em;
      }
    }
  }
}

