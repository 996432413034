:global {
  .navItemsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--px8);
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      z-index: 1;
      width: 100%;
    }

    .navLink {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      min-height: var(--px48);
      padding: 0.3125em 1em; //5px 16px
      border-radius: var(--px8);
      background-color: transparent;
      color: white;
      overflow: hidden;
      transition: 0.3s ease;

      &:focus {
        box-shadow: var(--focus);
      }

      &.isDisabled {
        pointer-events: none;
        cursor: default;
        color: var(--warm-grey);
      }

      //gradient text needs to be in a block or inline-block element to work on safari cannot be flex or inline-flex
      .navLinkWrapper {
        display: block;
        // prevents text following an svg from leaking over to next row when expanding navmenu
        flex-shrink: 0;

        >.navLinkText {
          padding-left: var(--px8);
          transition: all 0.3s ease;
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: none;
        transition: 0.3s ease;
      }

      &:before {
        z-index: -2;
        background: transparent;
      }

      &:after {
        bottom: 0;
        right: 0;
        z-index: -1;
        max-width: calc(100% - var(--px2));
        max-height: calc(100% - var(--px2));
        margin: auto;
        background-color: var(--dark);
      }

      &:hover {
        &:before,
        &:after {
          background-color: var(--dark-medium);
        }
      }

      i,
      .navLinkText {
        font-size: var(--px14);
      }

      svg {
        vertical-align: middle;
        max-width: var(--px14);
        max-height: var(--px14);
      }

      &.active {
        .navLinkWrapper {
          background: linear-gradient(114.51deg, #0BF6CC 4.29%, rgba(7, 61, 200, 1) 77.45%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &:after {
          background-color: var(--dark);
        }

        &:before {
          background: linear-gradient(114.51deg, #0BF6CC 4.29%, rgba(7, 61, 200, 0) 77.45%);
        }
      }

      &.textHidden {
        width: var(--px48);
        max-width: unset;
        padding: 0.5em;
      }
    }
  }
}
