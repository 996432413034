:global .poolCard {
  position: relative;
  z-index: 1;
  padding: var(--px24);
  border-radius: 1em;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  transition: box-shadow 0.3s ease;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: var(--px1) solid transparent;
    background: none;
    border-radius: inherit;
    background: var(--gradient) border-box;
    mask:
      linear-gradient(to right, black 100%, black 100%) content-box,
      linear-gradient(to right, black 100%, black 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    background: var(--dark-medium);
    border-radius: inherit;
  }

  &.isSelected,
  &:hover,
  &:focus-within {
    outline: 0;

    &:before {
      opacity: 1;
    }
  }

  .buttonContainer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  .poolCardHeader {
    position: relative;
    overflow: hidden;
    padding-right: calc(var(--px48) * 2);
    cursor: pointer;
  }

  .poolCardTokenList.icons {
    mask: var(--fade-mask);
    >li img {
      margin-left: 1px;
    }
  }

  .poolCardBodyHeader {
    margin: 0 0 var(--px8);
    padding: 0 0 var(--px8);
    border-bottom: var(--border);
  }

  .poolCardTokenList.name {
    display: flex;
    align-items: center;
    gap: calc(var(--px8) / 2);
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    mask: var(--fade-mask);

    >li span {
      font-size:  var(--px14);
      line-height: 1.2;
    }
  }

  .poolCardBody {
    cursor: pointer;
  }

  .poolCardChain {
    span {
      color: var(--warm-grey);
      font-size: var(--px14);
      line-height: 1.2;
    }
  }

  .poolCardRewardsTitle {
    margin-bottom: 0.66em; //8px
    font-size: var(--px12);
    color: var(--warm-grey);
  }

  .poolCardRewardsList {
    margin: 0;
    padding: 0;
    font-size: var(--px12);
    list-style: none;

    > li {
      margin-top: 0.5em;
    }
    .poolAttribute{
      color: var(--warm-grey);
      margin-right: 0.5em;
    }

    .tokenGridList {
      grid-auto-columns: var(--px14);
      margin-bottom: 0;
      padding-bottom: 0;
      overflow: unset;
      width: auto;
      max-width: unset;
      font-size: var(--px22);
      margin-left: var(--px4);

      > li {
        &:last-child {
          grid-column: span 2;
        }
        span {
          width: var(--px24);
          height: var(--px24);
        }
      }
    }
  }

  .poolCardFooter {
    padding-top: var(--px24);
  }
}
