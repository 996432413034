:global .statistics {
  // Chart shades variations
  --chart-variation-neon-purple: #FF00FF;
  --chart-variation-neon-blue: #00FFFF;
  --chart-variation-neon-turquoise: #00FFEF;
  --chart-variation-neon-green: #00FF00;
  --chart-variation-neon-red: #FF0000;
  --chart-variation-neon-orange: #FFAA00;
  --chart-variation-neon-pink: #FF00AA;
  --chart-variation-neon-yellow: #FFFF00;
  --chart-variation-neon-magenta: #FF00FF;
  --chart-variation-neon-cyan: #00FFFF;
  --chart-variation-electric-purple: #8A2BE2;
  --chart-variation-electric-blue: #00BFFF;
  --chart-variation-electric-green: #00FF7F;
  --chart-variation-electric-red: #FF0033;
  --chart-variation-electric-orange: #FF6600;
  --chart-variation-electric-pink: #FF1493;
  --chart-variation-electric-yellow: #FFFF33;
  --chart-variation-electric-magenta: #FF33FF;
  --chart-variation-electric-cyan: #00FFFF;
  --chart-variation-electric-lime: #CCFF00;

  .statsPageWrapper {
    width: 100%;
    max-width: 74em; //1184px
    margin: 0 auto;
    padding: var(--px24) 0 var(--px80);
  }

  .statsSectionHeader {
    //margin-bottom: var(--px32);
    padding-top: var(--px24);
    padding-bottom: var(--px32);

    @media (max-width: 991px)  {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  .statsSectionHeaderTitle {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1em;
    margin-bottom: 1em;

    h1 {
      margin-bottom: 0;
    }


    >fieldset {
      width: auto;
      max-width: unset;
    }

    @media (max-width: 991px) {
      margin-bottom: 0;

      > fieldset {
        width: 100%;
        max-width: 100%;
      }
    }
  }


  .statsSectionGrid {
    display: grid;
    gap: var(--px32);

    &.statsSectionHeaderGrid {
      grid-template-columns: repeat(3, 1fr);
    }

    &.statsSectionGraphGrid,
    &.statsSectionTable {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .statsSectionGridItem {
    display: flex;
    flex-direction: column;
    padding: var(--px24);
    border: var(--border);
    border-radius: 1em;

    .statsGraphWrapper {
      position: relative;
    }
  }

  .statsSectionGridItemTitle {
    margin-bottom: 0;
    font-size: var(--px14);
  }

  .statsSectionGridItemValue {
    font-size: var(--px24);
  }

  .statsSectionGraphGridItemHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--px8);
    margin-bottom: var(--px32);
  }

  .statsSectionGraphBtnGroup {
    margin-bottom: var(--px32);
  }

  .statsSectionTable {
    margin-top: var(--px48);
  }

  .statsSectionTableHeader {
    gap: var(--px24);
    margin-bottom: var(--px32);

    h2 {
      margin-bottom: 0;
    }
  }

  .highcharts-container {
    .highcharts-tooltip-box {
        fill: black;
        fill-opacity: 0.6;
        stroke-width: 0;
    }

    .highcharts-tooltip text {
        fill: #bbb !important;
        text-shadow: 0 0 3px black;
    }

    .highcharts-label text {
      fill: #aaa !important;
    }
  }

  .statsSectionTable .table {
    thead tr th,
    tbody tr td {
      &:not(:first-child) {
        text-align: right;
        width: 25%;
        .tableHeaderColumnWithIcon {
          display: flex;
          justify-content: flex-end;
          gap: var(--px8);
          .icon{
            width: var(--px14);
            height: var(--px14);
          }
        }
      }

    }

    tfoot tr td.defaultEmpty {
      padding: 1em 0;
    }
  }

  .variation {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    &:before,
    &:after {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:after {
      content: "";
      width: var(--px8);
      height: var(--px8);
      margin-left: var(--px4);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    &.higher {
      color: var(--green);

      &:before {
        content: "+";
      }

      &:after {
        background-image: url("../assets/icons/icn-up.svg");
      }
    }

    &.lower {
      color: var(--red);

      &:before {
        content: "-"
      }

      &:after {
        background-image: url("../assets/icons/icn-down.svg");
      }
    }
  }

  @media (max-width: 991px) {
    .statsPageWrapper {
      padding: var(--px24) 0 0;
    }

    .statsSectionGrid {
      display: flex;
      flex-direction: column;
      gap: var(--px8);

      &.statsSectionGraphGrid {
        gap: var(--px24);
      }
    }

    .statsSectionHeader {
      margin-bottom: var(--px24);
    }

    .statsSectionGraphGridItemHeader,
    .statsSectionTableHeader {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      > * {
        width: 100%;
      }

      .inlineFlexbox .formGroupSelect {
        flex: 1;
      }
    }

    .statsSectionTable {
      margin-top: var(--px24);

      .table {
        thead tr th:first-child {
          font-size: var(--px12);
          padding: 0.33em 0.66em;
        }

        thead tr th,
        tbody tr td {
          &:not(:first-child) {
            width: unset;
            font-size: var(--px12);
            padding: 0.33em 0.66em;
          }
        }
      }
    }
  }
  .statisticsGridItemsSectionTablePagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding-top: var(--px32);

    .btnTablePagination {
      &:disabled {
        pointer-events: none;
        cursor: default;

        svg {
          color: var(--warm-grey);
        }
      }

      &.isDragging {
        color: var(--green);
        width: 6em; //96px
        max-width: 6em;

        &:hover, &:focus, &:active, &.hover {
          color: fuchsia;
        }
      }
    }

    .tablePaginationBulletBtnList {
      flex: 1 1 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: var(--px12);
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
      }

      .btnTablePaginationBullet {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--px12);
        height: var(--px12);
        padding: 0;
        background-color: var(--purple);
        border: 0;
        border-radius: var(--px2);
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:focus {
          box-shadow: var(--focus);
        }

        &.isCurrent {
          opacity: 1;
          pointer-events: none;
          cursor: default;
        }

        &.isDragging {
          background-color: var(--green);
          width: var(--px24);
          max-width: var(--px24);

          &:hover, &:focus, &:active, &.hover {
            background-color: fuchsia;
            opacity: 1;
          }
        }
      }
    }
  }
}
