:global .dashboardWelcomeDialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: var(--px24);
  background-color: rgba(8,8,25,70%);
  overflow-y: auto;

  .dashboardWelcomeDialog {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 37.1875em; /*595px*/
    background-color: var(--dark);
    border-radius: 1em;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  .dashboardWelcomeDialogBody {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 var(--px48) var(--px48);

    &:before {
      content: "";
      display: block;
      width: 100%;
      max-width: 100%;
      aspect-ratio: 595/368;
    }

      >.buttonContainer {
        margin-top: var(--px24);
      }
  }

  @media (max-width: 991px) {
      padding: var(--px8) var(--px8) calc(var(--px80) + var(--px8));

    .dashboardWelcomeDialogBody {
      padding: 0 var(--px24) var(--px24);
    }
  }
}
