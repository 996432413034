:global {
  .myAssetsManageTokensModal {
    .navTabs {
      width: 100%;
      max-width: 100%;
      margin-bottom: var(--px32);

      .btn {
        --btn-padding-x: var(--px30);
        width: 100%;
        white-space: normal;
      }
    }

    form {
      .depositFromFieldset,
      .withdrawToFieldset{
        display: flex;
        flex-direction: column;
        row-gap: var(--px32);
        margin-bottom: var(--px32);
      }

      .depositToFieldset,
      .withdrawFromFieldset,
      .derivativeFromFieldset {
        margin-bottom: var(--px32);
      }

      .derivativeToFieldset,
      .derivativeFromFieldset {
        position: relative;
        padding-bottom: var(--px32);

        .switchIcon {
          position: absolute;
          left: 0;
          right: 0;
          bottom: calc(var(--px24)*-1); //-36px
          margin: 0 auto;
        }
      }

      .selectTokenSubFieldset {
        &.derivativeTo {
          >.flexbox {
            position: static;
            z-index: unset;
          }

          .tokenIcon {
            flex-shrink: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: var(--px24);
            height: var(--px24);

            > svg, > img {
              width: 100%;
              max-width: 100%;
              height: auto;
              max-height: 100%;
              object-fit: contain;
            }
          }
        }

        @media (max-width: 991px) {
          >.flexbox {
            position: static;
            flex-direction: column;
            align-items: flex-start;

            >.formGroup,
            >.formGroup:not(.formGroupSelect){
              width: 100%;
              flex: unset;
            }

            >.formGroupSelect .customSelect {
              position: relative;
            }
          }

          .fromTokenUsdDepositFormGroup {
            flex: unset;
          }
        }
      }

      .infoText {
        margin-bottom: 0;
        font-size: var(--px13);
      }

      .buttonContainer {
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        padding-top: var(--px32);
      }
    }
  }

  .pendingUnmintSection {
    margin-top: var(--px32);

    .pendingUnmintTableSectionHeader {
      justify-content: space-between;
      margin-bottom: var(--px24);

      .h3 {
        margin-bottom: 0;
      }
    }

    .pendingUnmintTable {
      table-layout: fixed;

      thead tr th:first-child,
      tbody tr td:first-child {
        width: 15%;
      }

      thead tr th:not(:first-child):not(:last-child),
      tbody tr td:not(:first-child):not(:last-child){
        text-align: center;
      }

      thead tr th:last-child,
      tbody tr td:last-child{
        text-align: right;
      }

      .date {
        display: flex;
        flex-direction: column;
        color: var(--warm-grey);
        font-weight: 300;
        letter-spacing: -0.03em;

        >em {
          font-size: var(--px14);
          font-style: normal;
        }
      }
    }
  }
}
