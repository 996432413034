:global .legal {
  main {

    h1 {
      text-align: center;
    }

    h2,h3 {
      text-align: left;
    }

    p {
      text-align: justify;
    }

    strong {
      font-weight: 700;
    }

  }
}
