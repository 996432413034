:global .tradeVisualiserModal {

  .sectionModalHeader {
    margin-bottom: var(--px32);
    padding-bottom: var(--px32);
    text-align: center;
    overflow-x: auto;
  }

  .visualiserHeader {

    >.inlineFlexbox {
      gap: 1em;
      flex-wrap: nowrap;
    }

    .visualiserHeaderTokenValue {
      flex-shrink: 0;
      white-space: nowrap;
    }

    .visualiserHeaderToken {
      display: inline-flex;
      width: var(--px64);
      height: var(--px64);
      border-radius: 100%;
      background-color: var(--body-bg-color);
      object-fit: contain;

      @media (max-width: 991px) {
        width: var(--px48);
        height: var(--px48);
      }

      @media (max-width: 575px) {
        width: var(--px32);
        height: var(--px32);
      }
    }

    .visualiserHeaderSwapIconWrapper {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--warm-grey)
    }
  }

  .historicalNote {
    margin-bottom: var(--px32);
    color: var(--warm-grey);
    text-align: center;
  }

  .visualiserList {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;

    &:after {
      content: "";
      position: absolute;
      top: 1em;
      left: 2em;
      z-index: -1;
      width: var(--px1);
      height: calc(100% - 2em);
      background-color: var(--transparent-white);
    }

    .visualiserListItem {
      border-radius: var(--px8);
    }

    .visualiserListItemWrapper {
      justify-content: space-between;
      padding: 1em 0.5em;
    }

    .visualiserListItemTokenImage {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--px48);
      height: var(--px48);
      background-color: var(--body-bg-color);
      border-radius: 100%;

      >img {
        width: 100%;
        max-width: var(--px32);
        height: auto;
        object-fit: contain;
      }
    }

    .visualiserListItemToken {
      font-size: var(--px14);
    }

    .visualiserListItemValueConversion {
      color: var(--warm-grey);
      font-size: var(--px12);
    }

    &.operations {
      row-gap: var(--px8);

      &:after {
        display: none;
      }

      .visualiserListItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: var(--px8);
        padding: 1em;
        background-color: var(--body-bg-color);

        @media (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: unset;
        }

        &.failed {
          box-shadow: inset 0 0 0 var(--px1) var(--red);

          .failedMessage {
            color: var(--red);

            svg {
              flex-shrink: 0;
              max-width: 1em;
              max-height: 1em;
            }
          }
        }

        .visualiserListItemAction {
          flex: 1 1 auto;
        }

        &.mint {
          .visualiserListItemAction {
            gap: 1em;
          }

          .visualiserListItemActionType {
            font-size: var(--px14);
          }
        }

        &:not(.mint) {
          .visualiserListItemActionType {
            margin-left: var(--px4);
          }

          .visualiserListItemAction {
            gap: 0;
          }
        }

        .visualiserListItemActionIcon {
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--px32);
          height: var(--px32);
          color: var(--warm-grey);

          svg {
            max-width: 1em;
            max-height: 1em;
          }
        }

        .tokenGridList {
          grid-auto-columns: var(--px14);
          margin-bottom: 0;
          padding-bottom: 0;
          overflow: unset;
          width: auto;
          max-width: unset;

          > li {
            &:last-child {
              grid-column: span 2;
            }
            span {
              width: var(--px24);
              height: var(--px24);
            }
          }
        }

        .visualiserListItemActionType {
          display: inline-flex;
          flex-direction: column;

          em {
            font-size: var(--px14);
            font-style: normal;
            line-height: 1;
          }

          small {
            color: var(--warm-grey);
            font-size: var(--px13);
            text-transform: capitalize;
          }
        }

        .visualiserListItemActionFees {
          display: inline-flex;
          gap: 1em;
          color: var(--warm-grey);
        }
      }
    }
  }

  .feesIconWrapper {
    display: inline-flex;
    align-items: baseline;
    justify-content: center;
    width: 1em;
    height: 1em;
    line-height: 1;

    svg {
      max-width: 1em;
      max-height: 1em;
      opacity: 0.5;
    }

    .xs {
      width: 1.2em;
      height: 1.2em;
    }
  }

  .inlineFlexbox {
    &.arch {
      gap: var(--px8);
    }
    &.gravitate {
      gap: var(--px4);
    }

    &.arch,
    &.gravitate {
      &.fees {
        gap: var(--px2);
        svg {
          max-width: 0.9em;
          max-height: 0.9em;
        }
      }
    }
  }

  .sectionModalFooter {
    margin-top: var(--px8);
  }

  .tradeVisualiserTotalGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    gap: var(--px8);

    @media (max-width: 575px) {
      grid-template-columns: repeat(1,1fr);
    }
  }

  .tradeVisualiserTotalGridWrapper,
  .tradeVisualiserTotalGriditem {
    flex-direction: column;
    align-items: center;
  }

  .tradeVisualiserTotalGridWrapper {
    display: inline-grid;
    padding: var(--px24);
    border: var(--border);
    border-radius: 1em;
    gap: 0.5em;

    .tradeVisualiserTotalGriditem {
      display: flex;

      small {
        font-size: var(--px14);
        color: var(--warm-grey);
      }
    }
  }
}
