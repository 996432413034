ul.btnGroup {
  margin: 0;
  list-style: none;

  >li {
    flex: 1;
  }
}

.btnGroup {
  display: inline-flex;
  align-items: stretch;
  gap: var(--px4);
  padding: var(--px4);
  border-radius: var(--px8);
  box-shadow: inset var(--box-shadow);

  @media (max-width: 991px) {
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
  }

  &.vertical {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    .btn {
      justify-content: flex-start;
      width: 100%;
      max-width: 100%;
    }
  }

  &.noBorder {
    padding: 0;
    box-shadow: none;
    border-radius: unset;
  }

  > * {
    flex: 1;
  }

  .btn {
    --btn-bg-color: transparent;
    --btn-color: rgba(255,255,255,0.6);

    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    white-space: nowrap;

    &:hover {
      --btn-bg-color: var(--gradient);
      --btn-color: white;
    }

    &.isActive {
      --btn-bg-color: rgba(255,255,255,0.1);
      --btn-color: white;

      &:hover {
        --btn-bg-color: rgba(255,255,255,0.1);
        --btn-color: white;
      }
    }

    @media (max-width: 991px) {
      --btn-padding-x: var(--px8);
    }
  }

  input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &:focus ~ label {
      box-shadow: inset var(--focus);
    }

    &:disabled ~ label {
      pointer-events: none;
    }

    &:checked ~ label.btn {
      --btn-bg-color: rgba(255,255,255,0.1);
      --btn-color: white;

      i, svg {
        color: var(--purple);
      }

      &:hover {
        --btn-bg-color: rgba(255,255,255,0.1);
        --btn-color: white;

        i, svg {
          color: inherit;
        }
      }
    }
  }
}