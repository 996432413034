:global .walletModal {
  .sectionModalHeader p {
    margin-top: var(--px32);
  }

  .walletConnectListWrapper {
    margin: 0 0 var(--px32);
    padding: 0 0 var(--px32);
  }

  .buttonContainer.modalButtonContainer {
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column-reverse;

      >* {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
