:global {
  .dashboardGridItemMyHistory {
    .filterBtnGroup {
      width: 100%;
      max-width: 100%;
      margin-bottom: var(--px32);
    }

    .dashboardMyHistoryActionsDropdown {
      .btnGroup {
        box-shadow: none;

        .btn {
          svg {
            color: var(--purple);
          }

          &:hover {
            svg {
              color: inherit;
            }
          }
        }
      }
    }

    .table.dashboardHistoryTable {
      border-spacing: 0 var(--px8);

      thead tr th,
      tbody tr td {
        text-align: right;

        &:first-child {
          text-align: left;
        }

        &:nth-child(2) {
          text-align: center;
        }

        &:nth-child(3){
          padding-right: 0;
        }
      }

      &.toasts{
        thead tr th,
        tbody tr td {
          &:nth-child(2) {
            text-align: left;

            // prevent long messages from taking over the display
            span{
              display: inline-block;
              max-height: 12em;
              overflow-y: auto;
              word-break: break-word;
            }
          }
        }
      }

      .date {
        display: block;
        color: var(--warm-grey);
        font-weight: 300;
        letter-spacing: -0.03em;

        > em {
          font-style: normal;
        }

        @media (min-width: 992px) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          > em {
            font-size: var(--px14);
            font-style: normal;
          }
        }
      }

      .tokenConversionIcon {
        flex: 0 0 1em;
      }

      @media(max-width: 991px) {
        thead tr th:first-child,
        tbody tr td:first-child {
          display: none;
        }

        thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
          text-align: left;
          width: 100%;

          .date {
            margin-bottom: var(--px8);
          }
        }
      }
    }
  }
}
