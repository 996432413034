:global .lockupsSectionTable {
  .lockupsSectionTableFooter .buttonContainer {
    margin-top: var(--px32);
  }

  >.table {
    table-layout: auto !important;

    &:not(:first-of-type) {
      thead tr th {
        padding: 0 var(--px8);
        border: 0;
        line-height: 0;

        span {
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    &:last-of-type,
    &:only-of-type {
      border-bottom: 0;
      border-image-source: none;
      border-image-slice: unset;
    }

    thead tr th:not(:first-child):not(:last-child),
    tbody tr td:not(:first-child):not(:last-child) {
      text-align: left;
    }

    thead tr th,
    tbody tr td {
      text-align: left;

      &:nth-child(1) {
        padding-left: 0;
        width: var(--px64);
      }

      &:nth-child(2),
      &:nth-child(3) {
        text-align: left !important;
        width: 25%;
      }

      &:nth-child(4),
      &:nth-child(5){
        text-align: right !important;
        width: 25%;
      }
    }

    tbody tr td {
      border-bottom: 0;

      .inlineFlexbox {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }
    }

    @media (max-width: 991px) {
      tfoot .tableTFootFlexbox .btn {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100%;
          border: var(--px1) solid transparent;
          background: var(--dark-blue-gradient) border-box;
          border-radius: inherit;
          mask:
            linear-gradient(to right, black 0, black 100%) content-box,
            linear-gradient(to right, black 0, black 100%);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      }
    }
  }
}