:global .dashboardGridItemMyAssets {
  padding-bottom: var(--px32);

  .dashboardAssetsTable {
    thead tr th,
    tbody tr td {
      text-align: right;

      &:first-child {
        width: 45%;
        text-align: left;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 25%;
        text-align: center;
      }

      &:nth-child(4) {
        width: 0;
        padding-right: 0;
      }

      .amount {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .locked {
          color: var(--warm-grey);
          svg {
            width: .75rem;
          }
        }
      }

      .manageWithStatus {
        position: relative;

        .badge {
          position: absolute;
          top: 0.35em;
          right: 0.35em;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--px14);
          height: var(--px14);
          background: var(--red);
          border-radius: 50%;
          color: #ccc;
          .badgeNumber {
            font-size: var(--px8);
          }

          :hover {
            cursor: pointer;
          }
        }

        .unmints {
          background: var(--purple);
          color: white;
        }

        .claim {
          background: #186218;
        }
      }
    }

    @media (min-width: 992px) {
      > button.btn {
        white-space: nowrap;
      }
    }
  }



  .subGroup {
    border-top: var(--border);
    padding-top: var(--px8);

    .btnAddTokensToWallet {
      svg {
        max-width: unset;
        max-height: unset;
        height: var(--px22);
        width: var(--px22);

        path {
          fill: currentColor;
        }
      }
    }
  }

  > button.btn div {
    white-space: nowrap;
  }
}
