:global .customLoader {
  display: inline-block;

  &.isFixed {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .customLoaderSpinnerWrapper {
    --loader-size: 8em;
    flex-shrink: 0;
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: 100%;

    &.xss {
      --loader-size: 1.5em;
    }

    &.xs {
      --loader-size: 2em;
    }

    &.sm {
      --loader-size: 4em;
    }

    &.md {
      --loader-size: 6em;
    }
  }

  .customLoaderSpinner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    mask-image: url("../assets/images/loader.svg");
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center center;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: loaderAnimation 1s linear infinite;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background: conic-gradient(transparent, transparent 40%, var(--turquoise));
    }
  }
}