:global {
  .mobileNav {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 0 var(--px8);
    background-color: transparent;
  }

  .mobileNavWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--px8);
    width: 100%;
    max-width: 100%;
    padding: 1em;
    background-color: var(--dark);
    box-shadow: inset var(--box-shadow);
    border-radius: 1em;

    > .navItemsList {
      flex: 1;
      display: flex;
      flex-direction: unset;
      align-items: center;
      justify-content: space-evenly;
      width: auto;

      >li {
        width: auto;
      }
    }
  }
}