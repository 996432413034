:global .sales {
    //Page Radial Background
    &:after {
      background: url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
      background-size: 100% auto, cover;
    }

    //&:before {
    //  background-color: #080819
    //}

    .custom-scrollbar::-webkit-scrollbar-track {
      border-radius: 2em;
      background-color:#57586C;
    }

    .custom-scrollbar::-webkit-scrollbar {
      width: .5em;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 2em;
      background-color: var(--dark-medium);
    }


    // /* App.css */
    // .custom-scrollbar {
    //   scrollbar-width: thin;
    //   scrollbar-color: var(--dark-grey) var(--light-grey);
    // }

    // .custom-scrollbar::-webkit-scrollbar {
    //   width: thin;
    // }

    // .custom-scrollbar::-webkit-scrollbar-track {
    //   background: var(--light-grey);
    // }

    // .custom-scrollbar::-webkit-scrollbar-thumb {
    //   background: var(--dark-grey);
    // }

    .salesPageWrapper {
      width: 100%;
      max-width: 40em;
      margin: 0 auto;
      background-color: #080819
    }

    .salesSectionHeader {
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 1em;
      padding-bottom: 1rem;
      margin-bottom: 1em;
      padding-top: 1em;

      h1 {
        margin-bottom: 0;
      }

      >fieldset {
        width: auto;
        max-width: unset;
      }

      @media (max-width: 991px) {
        margin-bottom: 0;

        > fieldset {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .salesDetailsHeader {
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 1em;
      //padding-bottom: 1rem;
      margin-bottom: 1em;

      h1 {
        margin-bottom: 0;
      }


      >fieldset {
        width: auto;
        max-width: unset;
      }

      @media (max-width: 991px) {
        margin-bottom: 0;

        > fieldset {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .total-raised-title {
      text-align: center;
    }

    .sale-selector {
      width: 12em;
    }

    select {
      text-align: center;
      text-align-last: center;
      /* webkit*/
    }

    .horizontal-progress-bar-wrapper {
      width: 85%;
      margin: auto;
      text-align: center;

      @media (max-width: 575px) {
        width: 70%;
      }
    }

    .horizontal-progress-bar {
      display: flex;
      align-items: center;
      background-color: rgb(82, 82, 82);
      height: 4em;
      margin: 0;
      position: relative;
      width: 100%;
      border-radius: 0 2em 2em 0;
    }

    .progression-mid-element {
      position: absolute;
      width: 100%;
    }

    .progress-indicator {
      position: absolute;
      height: 4em;
      border-radius: 0 2em 2em 0;
      animation: animate 20s linear infinite;
    }



    // .progress-bar-background {
    //   position: absolute;
    //   height: 15rem;
    //   width: 20rem;
    //   z-index: -2;
    //   border-radius: 2em;
    //   background-color: rgba(12, 12, 39, 0.4);
    //   backdrop-filter: blur(37px);
    // }

    .progress-section {
      position: relative;
      text-align: center;
    }

    .section-title {
      position: absolute;
      top: -1.5em;
      left: 50%;
      transform: translateX(-50%);
      color: var(--warm-grey);
    }

    .start-date {
      position: absolute;
      bottom: 0;
      left: -3rem; /* Adjusted to be at the left of the progress section */
      transform: translate(0, 50%); /* Center vertically */
      margin-bottom: -2rem; /* Added margin to separate from the progress section */
      color: var(--warm-grey);
    }

    .end-date {
      position: absolute;
      bottom: 0;
      right: -3rem; /* Adjusted to be at the left of the progress section */
      transform: translate(0, 50%); /* Center vertically */
      margin-bottom: -2rem; /* Added margin to separate from the progress section */
      color: var(--warm-grey);
    }

    .vertical-line {
      height: 7rem;
      border: none;
      border-right: .25rem dotted var(--warm-grey);
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .vertical-line-start {
      height: 7rem;
      border: none;
      border-left: .25rem dotted var(--warm-grey);
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .input-bid-form-wrapper {
      text-align: -webkit-center;
    }

    .bidInput {
      max-width: 8em;
      text-align: right;
    }

    .statisticsGridItemsSectionTablePagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      padding-top: var(--px32);

      .btnTablePagination {
        &:disabled {
          pointer-events: none;
          cursor: default;

          svg {
            color: var(--warm-grey);
          }
        }

        &.isDragging {
          color: var(--green);
          width: 6em; //96px
          max-width: 6em;

          &:hover, &:focus, &:active, &.hover {
            color: fuchsia;
          }
        }
      }

      .tablePaginationBulletBtnList {
        flex: 1 1 auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: var(--px12);
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
        }

        .btnTablePaginationBullet {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: var(--px12);
          height: var(--px12);
          padding: 0;
          background-color: var(--purple);
          border: 0;
          border-radius: var(--px2);
          opacity: 0.5;
          cursor: pointer;
          transition: opacity 0.3s ease;

          &:focus {
            box-shadow: var(--focus);
          }

          &.isCurrent {
            opacity: 1;
            pointer-events: none;
            cursor: default;
          }

          &.isDragging {
            background-color: var(--green);
            width: var(--px24);
            max-width: var(--px24);

            &:hover, &:focus, &:active, &.hover {
              background-color: fuchsia;
              opacity: 1;
            }
          }
        }
      }
    }

    .tradeVisualiserTotalGridWrapper,
    .tradeVisualiserTotalGriditem {
      flex-direction: column;
      align-items: center;
    }

    .tradeVisualiserTotalGridWrapper {
      display: inline-grid;
      padding: var(--px12);
      border: var(--border);
      border-radius: 1em;
      gap: 0.5em;

      .tradeVisualiserTotalGriditem {
        display: flex;

        small {
          font-size: var(--px14);
          color: var(--warm-grey);
        }
      }
    }

    .inputBid {
      //max-width: 8em;
      flex: none ;
    }

    .slidingText {
      overflow: hidden; // add this line
      width: 100%;
    }

    .slidingText > * {
      position: relative;
      animation: 5s linear 0s outbidTextMoving;
    }

    .clockPulse {
      animation: pulse 3s linear infinite;
    }

    .statusPulse {
      animation: statusPulse 2s linear infinite;
    }

    .fillAvailable {
      width: 100%;
    }

    .bonusActivityRow {
      background-color: #d9480b;
      animation: outbitActivityBonusRowBackgroundFade 5s ease-in-out forwards;
    }

    .topBidderActivityRow {
      background-color: #073DC8;
      animation: outbitActivityTopBidderRowBackgroundFade 5s ease-in-out forwards;
    }

    .swiper-horizontal {
      border-radius: var(--px8);
      padding: var(--px4);

      .swiper-slide {
        border-radius: var(--px8);
      }
    }

    .outbidSectionCardGrid {
      @media (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
      }
    
      @media (max-width: 991px) {
        grid-template-columns: 1fr;
      }
    }
    
    .gridBox {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: var(--px32);
    
      @media (max-width: 1199px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: var(--px4);
      }
    
      @media (max-width: 767px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: var(--px14);
      }
    }

    .visualiserListItemTokenImage {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--px48);
      height: var(--px48);
      background-color: var(--body-bg-color);
      border-radius: 100%;
  
      >img {
        width: 100%;
        max-width: var(--px32);
        height: auto;
        object-fit: contain;
      }
    }

}



:global .outbidCard {
  position: relative;
  z-index: 1;
  padding: var(--px24);
  border-radius: 1em;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  transition: box-shadow 0.3s ease;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: var(--px1) solid transparent;
    background: none;
    border-radius: inherit;
    background: var(--gradient) border-box;
    mask:
      linear-gradient(to right, black 100%, black 100%) content-box,
      linear-gradient(to right, black 100%, black 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    background: var(--dark-medium);
    border-radius: inherit;
  }

  &.isSelected,
  &:hover,
  &:focus-within {
    outline: 0;

    &:before {
      opacity: 1;
    }
  }

  .buttonContainer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  .outbidCardHeader {
    position: relative;
    overflow: hidden;
    padding-right: calc(var(--px48) * 2);
    cursor: pointer;
  }

 

  .poolCardBodyHeader {
    margin: 0 0 var(--px8);
    padding: 0 0 var(--px8);
    border-bottom: var(--border);
  }

  .poolCardTokenList.name {
    display: flex;
    align-items: center;
    gap: calc(var(--px8) / 2);
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    mask: var(--fade-mask);

    >li span {
      font-size:  var(--px14);
      line-height: 1.2;
    }
  }

  .poolCardBody {
    cursor: pointer;
  }

  .poolCardChain {
    span {
      color: var(--warm-grey);
      font-size: var(--px14);
      line-height: 1.2;
    }
  }

  .poolCardRewardsTitle {
    margin-bottom: 0.66em; //8px
    font-size: var(--px12);
    color: var(--warm-grey);
  }

  .poolCardRewardsList {
    margin: 0;
    padding: 0;
    font-size: var(--px12);
    list-style: none;

    > li {
      margin-top: 0.5em;
    }
    .poolAttribute{
      color: var(--warm-grey);
      margin-right: 0.5em;
    }

    .tokenGridList {
      grid-auto-columns: var(--px14);
      margin-bottom: 0;
      padding-bottom: 0;
      overflow: unset;
      width: auto;
      max-width: unset;
      font-size: var(--px22);
      margin-left: var(--px4);

      > li {
        &:last-child {
          grid-column: span 2;
        }
        span {
          width: var(--px24);
          height: var(--px24);
        }
      }
    }
  }

  .poolCardFooter {
    padding-top: var(--px24);
  }
}
