:global .walletConnectList {
  display: flex;
  flex-direction: column;
  row-gap: var(--px8);
  margin: 0;
  padding: 0;
  list-style: none;

  .walletConnectListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--px8);
    padding: var(--px8) 0 0;
    border-top: 1px solid var(--transparent-white);

    .btn {
      &:first-child {
        width: 100%;
        max-width: calc(50% - var(--px8) / 2);
        justify-content: flex-start;

        svg {
          max-width: 1.5em;
          max-height: 1.5em;
        }
      }

      &[data-size="sm"] {
        .btnIcon i.feather {
          font-size: 1.5em;
        }
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;

      .btn:first-child {
        max-width: 100%;
      }
    }
  }
}