:root {

  --body-font-family: 'JetBrains Mono', monospace;
  --body-font-size: 16px;

  //Measurements
  --px1: 0.0625em;
  --px2: 0.125em;
  --px3: 0.1875em;
  --px4: 0.25em;
  --px8: 0.5em;
  --px-8: -0.5em;
  --px12: 0.75em;
  --px13: 0.8125em;
  --px14: 0.875em;
  --px22: 1.375em;
  --px24: 1.5em;
  --px30: 1.875em;
  --px32: 2em;
  --px36: 2.25em;
  --px40: 2.5em;
  --px48: 3em;
  --px64: 4em;
  --px72: 4.5em;
  --px80: 5em;

  //colors
  --body-bg-color: #080819;
  --body-font-color: #eee;

  --dark: #0c0c27;
  --dark-medium: #111130;
  --purple: #8276f9;
  --blue: #073DC8;
  --turquoise: #0bf6cc;
  --green: #61FF00;
  --red: #DB2C4B;
  --orange: #f1c40f;
  --warm-grey: rgba(242, 239, 237, 0.6);
  --transparent-white: rgba(255,255,255,0.1);

  --dark-blue-gradient: linear-gradient(92.22deg, #073DC8 0.58%, rgba(11, 246, 204, 0.4), rgba(11, 246, 204, 0) 100%);
  --gradient: linear-gradient(92.22deg, #0BF6CC 0.58%, #073DC8 100%);
  --red-gradient: linear-gradient(92.22deg, #f6590b 0.58%, #c80a07 100%);
  --green-gradient: linear-gradient(92.22deg, #389300 0.58%, #235c00 100%);

  --fade-mask: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,1) 65%, rgba(0,255,0,0) 100%) 100% 50% / 100% 100% repeat-x;
  --focus : 0 0 var(--px1) var(--px3) rgba(255,255,255,0.2);

  //elements
  --navSidebar-max-width: 15em; //240px
  --border: var(--px1) solid var(--transparent-white);
  --box-shadow: 0 0 0 var(--px1) var(--transparent-white);
}

@media (min-width: 1921px) {
  :root {
    --body-font-size: 0.833vw; //16px
  }
}


