:global .astrovaultTokenIcon {
  flex: 0 0 1.33em;
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.33em;
  height: 1.33em;
  border: var(--px1) solid currentColor;
  border-radius: 100%;
  color: currentColor;

  svg {
    max-width: 50%;
    max-height: 50%;
  }

  &.borderGradient {
    border: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      border: var(--px1) solid transparent;
      border-radius: 100%;
      background: var(--gradient) border-box;
      mask:
        linear-gradient(to right, black 0, black 100%) content-box,
        linear-gradient(to right, black 0, black 100%);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}