.swiper,
.swiper-container {
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

.swiper-wrapper {
  align-items: stretch;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  height: unset !important;
}

.swiper-button-prev,
.swiper-button-next {
  position: static;
  top: unset;
  margin-top: 0;
  z-index: unset;

  &:after {
    display: none;
  }
}

.swiper-pagination {
  position: static;
  z-index: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--px12);
  text-align: center;
  transition: 300ms opacity;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: var(--px12);
  height: var(--px12);
  margin: 0;
  background:var(--purple);
  border-radius: var(--px2);
  opacity: 0.5;

  &.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--purple);
  }
}

.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
