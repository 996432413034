:global .poolCardDetailsModal {

  .poolCardModalSectionHeader {
    margin-bottom: var(--px32);
    padding-bottom: var(--px32);
  }

  .poolCardModalTokenListIconWrapper {
    width: 100%;
    max-width: 100%;
    padding-right: var(--px48);
    overflow: hidden;
  }

  .poolCardModalTokenListNameWrapper {
    flex: 1;
    overflow: hidden;
  }

  .poolCardModalTokenList {
    &.icons {
      grid-auto-columns: 3.375em; //54px
      mask: var(--fade-mask);
      padding-right: 2em;

      > li span {
        width: var(--px64);
        height: var(--px64);
      }
    }

    &.name {
      display: flex;
      align-items: center;
      gap: 0.5em;
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0 2em 0 0;
      overflow-x: auto;
      list-style: none;
      mask: var(--fade-mask);

      >li span {
        font-size: var(--px24);
        line-height: 1.2;
      }
    }
  }

  .poolCardModalChain {
    span {
      color: var(--warm-grey);
      font-weight: 200;
    }
  }

  .poolCardModalSectionTabs {
    .navTabs {
      margin-bottom: var(--px32);
    }
  }

  .poolCardTokenList.name {
    display: flex;
    align-items: center;
    gap: calc(var(--px8) / 2);
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;
    mask: var(--fade-mask);

    >li span {
      font-size:  var(--px14);
      line-height: 1.2;
    }
  }

  .poolCardChain {
    span {
      color: var(--warm-grey);
      font-size: var(--px14);
      line-height: 1.2;
    }
  }

  .poolCardModalSectionTable {
    padding-bottom: var(--px32);
    margin-bottom: var(--px32);

    >.table {
      table-layout: fixed;
      width: 100%;
      max-width: 100%;

      @media (min-width: 992px) {
        thead tr th:not(:first-child):not(:last-child),
        tbody tr td:not(:first-child):not(:last-child) {
          text-align: center;
        }

        &.isResponsive thead tr th:last-child,
        &.isResponsive tbody tr td:last-child {
          width: 45%;
        }
      }

      &.isResponsive tbody tr td {
        vertical-align: top;
      }

      thead tr th:last-child,
      tbody tr td:last-child {
        text-align: right;
        padding-right: 0;

        .formGroup input {
          text-align: right;
        }
      }

      thead tr th > span {
        font-size: var(--px14);
        letter-spacing: -0.03em;
      }

      .flexbox {
        height: auto;
        min-height: var(--px48);
        flex-wrap: wrap;

        &.deposited {
          justify-content: center;
        }
      }

      .inlineFlexbox {
        flex-wrap: wrap;
      }

      tfoot {
        .tableTFootFlexbox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: var(--px8);
          padding: 0 0 var(--px8);

          &:only-child {
            padding: 1em 0;
          }

          &:first-child:not(:only-child):not(:last-child) {
            padding-top: 1em;
          }

          &.flexStart {
            align-items: flex-start;
          }

          &.withBorderBottom {
            margin-bottom: 0.75em;
            padding-bottom: 0.75em;
            border-bottom: var(--border);
          }

          small {
            color: var(--warm-grey);
            font-size: var(--px14);
            font-weight: 200;
          }

          .tableTFootColumn {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }

        .formGroupSwitch {
          margin-top: var(--px24);

          &.withGradientBorderBottom {
            margin-bottom: var(--px24);
            padding-bottom: var(--px24);
          }

          .formGroupSwitchWrapper {
            justify-content: space-between;
          }

          .label {
            p {
              margin-bottom: 0;
            }
          }
        }

        p.tableTFootNote {
          padding: 0.75em 0;
          border-top: var(--border);
          border-bottom: var(--border);

          span {
            font-size: var(--px12);
            color: var(--warm-grey);
          }
        }
      }

      @media (max-width: 991px) {
        &.isResponsive.col-3 {
          thead tr {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            th:nth-child(2) {
              text-align: right !important;
            }
          }

          tbody tr:not(.gradientRow) {
            grid-template-columns: repeat(4, 1fr);
            align-items: stretch;
            grid-template-areas:
                "grid-1 grid-1 grid-2 grid-2"
                "grid-3 grid-3 grid-3 grid-3";

            td {
              &:first-child {
                grid-area: grid-1;
              }
              &:nth-child(2) {
                grid-area: grid-2;

                .flexbox {
                  &.deposited {
                    justify-content: flex-end;
                  }
                }
              }

              &:nth-child(3) {
                grid-area: grid-3;
              }
            }
          }
        }
      }
    }

    .withdrawalFees {
      &.bold {
        font-weight: 900;
        color: var(--orange);

        &.red {
          color: var(--red);
        }
      }
    }

    .highFeeButton {
      border: 3px dotted var(--red);
      --btn-bg-color: rgba(11, 28, 72, 0.7);
    }

    section {
      &.highFee {
        margin-top: 1.5em;

        .highFeeText {
          font-weight: 900;
          color: var(--red);
          font-size: 1.25em;
        }

        label {
          &.customSwitch {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .poolCardModalSectionRewards {
    z-index: 1;
    margin-bottom: var(--px32);
    padding-bottom: var(--px32);

    >.flexbox {
      justify-content: space-between;

      p {
        margin-bottom: calc(var(--px8) / 2);
      }

      .userRewardsAmount > span {
        font-size: var(--px24);
      }
    }
  }

  .poolCardModalSectionRewardsList {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: calc(var(--px8) / 2);
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: auto;

    >li {
      display: inline-flex;
      align-items: center;
      padding: 1em;
      background-color: rgba(8, 8, 25, 0.4);
      border-radius: 1em;
      color:  var(--warm-grey);
      white-space: nowrap;

      >span {
        font-size: var(--px14);
      }
    }
  }

  .btnGroup.navTabs {
    width: 100%;
    max-width: 100%;
  }

  .horizontalSlider {
    width: 100%;
    max-width: 100%;
    height: var(--px24);
    margin: var(--px8) 0;

    .horizontalSliderThumb {
      position: absolute;
      top: 50%;
      z-index: 1;
      height: 100%;
      transform: translateY(-50%);
      cursor: pointer;

      .thumbNowValue,
      .thumbHandle {
        display: inline-flex;
        align-items: center;
      }

      .thumbNowValue {
        position: absolute;
        left: 50%;
        bottom: 100%;
        justify-content: center;
        padding: 0.5em;
        font-size: var(--px12);
        transform: translateX(-50%);
      }

      .thumbHandle {
        width: var(--px8);
        height: var(--px24);
        background-color: var(--dark);
        border: 1px solid var(--warm-grey);
        border-radius: 0;
      }
    }

    .horizontalSliderTrack {
      top: 50%;
      z-index: 0;
      height: var(--px8);
      border: 1px solid var(--warm-grey);
      border-radius: 1em;
      background-color: transparent;
      transform: translateY(-50%);

      &.horizontalSliderTrack-0 {
        background: var(--gradient);
        background-color: unset;
        border: 0;
      }
    }
  }

}
