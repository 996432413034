:global .pool {
  .seviceStatus{
    .warning{
      color: darkorange;
    }
    margin: 1em 1em 1em 0;
  }

  .poolSectionHeader {
    margin-bottom: var(--px32);
    padding-top: var(--px24);
    padding-bottom: var(--px32);

    @media (max-width: 991px)  {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  .poolSectionHeaderTitle {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1em;
    margin-bottom: 1em;

    h1 {
      margin-bottom: 0;
    }


    >fieldset {
      width: auto;
      max-width: unset;
    }

    @media (max-width: 991px) {
      margin-bottom: 0;

      > fieldset {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .gridBox {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: var(--px32);

    @media (max-width: 1199px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: var(--px4);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: var(--px14);
    }
  }

  .poolSectionCardGrid {
    @media (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  .customSelectOptionPrefix {
    svg, img {
      max-width: 1.1em;
      max-height: 1.1em;
    }
  }
}
