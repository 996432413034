:global .collectRewardsModal {
  .sectionModalHeader p {
    margin-top: var(--px32);
  }

  .collectRewardsList {
    margin: 0 0 var(--px32);
    padding: 0 0 var(--px32);
    list-style: none;

    .collectRewardsListItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--px8);
      padding-top: 1em;
      cursor: pointer;

      &:first-child,
      &:only-child {
        padding-top: 0;
      }

      &.total {
        em {
          font-size: var(--px14);
          font-style: normal;
        }

        .userRewardsAmount {
          font-size: var(--px24);
        }
      }
    }

    .collectRewardsDetailsListItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--px4);

      &.total {
        em {
          font-size: var(--px16);
          font-style: normal;
        }
      }
    }

    .collectRewardsListItemTokensList {
      margin: 0;
    }

    .collectRewardsListItemAmount {
      flex-shrink: 0;
      padding-bottom: 1em;
    }

    .poolRewardsExtraDetails {
      padding-bottom: 0.5em;
    }
    .poolRewardsDetails {
      text-align: right;
      margin-top: 0.5em;
      font-size: var(--px12);
    }
  }

  .buttonContainer {
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column-reverse;

      >* {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .tokenGridListRewards {
    position: relative;
    z-index: 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: var(--px22);
    justify-content: flex-start;
    width: auto;
    max-width: unset;
    margin-bottom: 0;
    margin: 0 0 1em;
    list-style: none;
    overflow: unset;
    padding-left: 0.5em;
  
    > li {
      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          z-index: $i;
        }
      }
  
      span {
        display: block;
        width: var(--px30);
        height: var(--px30);
        background-color: var(--dark-medium);
        border-radius: 100%;
  
        svg, img {
          display: block;
          width: 100%;
          max-width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    } 
  }

  .totalRewards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5em;
  }

  .tokenAmountsColumn {
    text-align: left;
  }

  .totalColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: right;

    &.total {
      em {
        font-size: var(--px14);
        font-style: normal;
      }

      .userRewardsAmount {
        font-size: var(--px24);
      }
    }
  }

  
}