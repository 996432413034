.Toastify__toast-container {
  --toastify-color-light: #fff;
  --toastify-color-dark: var(--dark-medium);
  --toastify-color-info: var(--warm-grey);
  --toastify-color-success: var(--green);
  --toastify-color-warning: var(--orange);
  --toastify-color-error: var(--red);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 20em;
}

.Toastify__toast-container--bottom-right {
  bottom: var(--px32);
  right: var(--px32);
}

.Toastify__toast-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--px24);
  height: var(--px24);
  margin-bottom: 1em;
  margin-inline-end: 0;
}

.Toastify__toast--info .Toastify__toast-icon  {
  color: var(--toastify-icon-color-info);
}

.Toastify__toast--success .Toastify__toast-icon  {
  color: var(--toastify-icon-color-success);
}

.Toastify__toast--warning .Toastify__toast-icon  {
  color: var(--toastify-icon-color-warning);
}

.Toastify__toast--error .Toastify__toast-icon  {
  color: var(--toastify-icon-color-error);
}

.Toastify__toast-body > div:last-child {
  width: 100%;
}

.customToastContainer {
  z-index: 9999;

  .customToast {
    margin-bottom: 0;
    padding: var(--px32);
    border-radius: 1em;
  }

  .customToastBody {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .customToastInner {
    width: 100%;
    max-width: 100%;

    .customToastContent {
      width: 100%;
      max-width: 100%;

      &.isBordered {
        border-bottom: var(--border);
        padding-bottom: var(--px24);
      }

      p:last-child,
      p:only-child{
        margin-bottom: 0;
      }

      >.buttonContainer {
       padding-top: var(--px24);
      }
    }

    .buttonContainer.customToastButtonContainer {
      margin-top: var(--px24);
      justify-content: space-between;
      font-family: var(--body-font-family);
    }
  }
}