:global {
  .dashboardSectionPools {
    .dashboardSectionPoolsHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--px8);
    }

    .dashboardSectionPoolsRewards {
      h3 {
        margin-bottom: 0.5em;
      }

      .userRewardsAmount {
        font-size: var(--px22);
        .tokenGridList {
          grid-auto-columns: var(--px14);
          margin-bottom: 0;
          padding-bottom: 0;
          overflow: unset;
          width: auto;
          max-width: unset;

          > li {
            &:last-child {
              grid-column: span 2;
            }
            span {
              width: var(--px24);
              height: var(--px24);
            }
          }
        }
      }
    }

    .dashboardSectionPoolsSlider {
      width: 100%;
      max-width: 100%;
      height: 100%;

      .swiper-wrapper {
        margin: var(--px32) 0;
      }

      .dashboardSectionPoolsSliderItem {
        flex-grow: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        min-height: 12.061875em; //192.99px
        padding: var(--px24);
        background-color: var(--dark-medium);
        border-radius: 1em;
        box-shadow: inset var(--box-shadow);
      }

      .dashboardSectionPoolsSliderItemDropdown {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 998;
        display: inline-block;
      }

      .dashboardSectionPoolsCustomDropdown {
        .customDropdownContentWrapper {
          display: flex;
          flex-direction: column;
        }

        .btnMyPoolsCustomDropdown {
          --btn-padding-x: 1em;

          width: 100%;
          max-width: 100%;
          text-align: left;
          white-space: nowrap;

          i, svg {
            color: var(--purple);
          }

          &:hover {
            i, svg {
              color: inherit;
            }
          }
        }
      }

      .dashboardSectionPoolsSliderItemHeader {
        padding-right: var(--px24);
        width: 100%;
        max-width: 100%;
        overflow: hidden;

        .dashboardSectionPoolsSliderTokenGridList {
          padding-right: 2em;
          mask: var(--fade-mask);
        }
      }

      .poolsTokenDetailsList {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: var(--px4);
        list-style: none;
        margin: 0;
        padding: 0;

        .divider {
          padding-bottom: var(--px8);
          border-bottom: var(--border);
        }

        li {
          display: grid;
          align-items: center;
          grid-template-columns: repeat(2, 1fr);
          gap: 1em;
          width: 100%;
          max-width: 100%;
          

          > em,
          > span {
            font-size: var(--px12);
            line-height: 1.33; //16px
          }

          > em {
            color: var(--warm-grey);
            font-style: normal;
          }

          > span {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
          }
        }
      }

      .dashboardSectionPoolsSliderButtonContainer {
        justify-content: space-between;
        margin-top: auto;

        > .btn {
          flex-shrink: 0;
        }
      }
    }
  }

  .swiperCustom {
    cursor: grab;
  }
  /* swiper class naming convention */
  .swiper-no-swiping {
    cursor: text;
  }

  .rewardAssetIconWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    line-height: 1;

    svg {
      max-width: 1em; 
      max-height: 1em;
      opacity: 0.5;
    }

    .xs {
      width: 2em;
      height: 2em;
    }
  }

  .rewardAssetIcon{
    width: 2em;
    height: 2em;
  }

  small {
    color: var(--warm-grey);
    font-size: var(--px14);
    font-weight: 200;
  }

  .collectRewardLoader {
    place-self: center;
  }
}
