:global .subFooterContent {
  padding: var(--px24) var(--px48);
  text-align: left;
  color: #555;

  .copyright {
    margin-bottom: 1em;
  }

  .subFooterLegal {
    display: block;
    font-size: var(--px8);
    line-height: 1.5;
  }


  @media (max-width: 991px) {
    padding: 1em 1em 8em 1em; //128px
  }
}