:global {
  .sectionHeaderUtilitiesMenu {
    position: relative;
    z-index: 10;
    display: inline-block;
  }

  .headerUtilitiesMenu {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.4s ease, opacity 0.5s ease-in-out;

    &.isOpen {
      opacity: 1;
      max-height: 45em; //2024-02-21: 34.375em; //550px
      transition: max-height 0.4s ease, opacity 0.5s ease-in-out;
    }
  }

  .headerUtilitiesMenuWrapper {
    padding: var(--px24) 0 0;
    width: 20em;
  }

  .headerUtilitiesMenuInner {
    position:relative;
    display: flex;
    flex-direction: column;
    row-gap: var(--px8);
    padding: var(--px24) 0;
    background-color: var(--dark-medium);
    border: var(--border);
    border-radius: var(--px8);
  }

  .headerUtilitiesMenuSwitch {
    position: absolute;
    top: 0;
    right: 0;
  }

  .headerUtilitiesMenuList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--px8);
    width: fit-content;
    max-width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;

    li, li > a {
      display: flex;
      align-items: center;
      gap: var(--px8);
      width: fit-content;
    }

    i {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1em;
      height: 1em;
    }

    &.primary {
      padding: 0 1em;

      a {
        justify-content: flex-start;
        min-width: 12.5em; //200px

        &:hover {
          color: white;

          svg {
            color: white;
          }
        }

        svg {
          color: var(--purple);
        }
      }
    }

    &.messages {
      @media (min-width: 992px) {
        flex-grow: 1;
        max-height: 15.625em; //250px
        overflow-y: auto;
        padding: 0 1em;
      }

      >li {
        align-items: baseline;
        font-size: 80%;
        line-height: 1.5;

        small {
          font-size: inherit;
        }

        &.success svg,
        &.error svg {
          flex-shrink: 0;
          width: 1.2em;
          height: auto;
          max-height: 1.2em;
          line-height: 1;
        }

        &.success svg {
          color: var(--green)
        }

        &.error svg {
          color: var(--red)
        }
      }
    }
  }

  .headerUtilitiesMenuListTitle {
    margin-bottom: 0;
    border-top: var(--border);

    @media (min-width: 992px){
      padding: var(--px8) 1em 0;
    }
  }

  .btn.btnMenu.btnMenuHeader {
    height: 2.625em;
    width: 2.625em; //42px
  }
  .btn.btnMenu.btnMenuHeader.btnMenuWithText {
    --btn-bg-color: transparent;
    --btn-min-height: unset;
    padding: 0 var(--px14);
    width: unset;
  }
}
