:global .customDropdownWrapper {
  .tippy-box {
    position: relative;
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    white-space: normal;
    outline: 0;
    box-shadow: none;
    transition-property: transform,visibility,opacity;
  }

  .tippy-content {
    position: relative;
    padding: var(--px8) 0;
    z-index: 1;

    > div {
      width: 100%;
      max-width: 100%;
    }
  }

  .customDropdownContent {
    display: flex;
    flex-direction: column;
    min-width: 12em;
    padding: 1em var(--px8);
    background-color: var(--dark);
    border-radius: 1em;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    white-space: normal;
    outline: 0;
    box-shadow: none;
    transition-property: transform,visibility,opacity;
  }
}
