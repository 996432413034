:global .trade {
  //Page Radial Background + images
  &:after {
    background: url("../assets/images/trade-bg.webp") no-repeat top left fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
    background-size: 100% auto, cover;

    @media (max-width: 991px) {
      background: url("../assets/images/trade-bg.webp") no-repeat top left fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
      background-size: 100% auto, cover;
    }

    @media (max-width: 575px) {
      background: url("../assets/images/trade-bg-mobile-2x.webp") no-repeat top center fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(118.8% 32.05% at 23.07% 0%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center;
      background-size: 100% auto, cover;
    }
  }

  .seviceStatus{
    .warning{
      color: darkorange;
    }
    margin: 1em;
  }
 
  .tradePageWrapper {
    width: 100%;
    max-width: 36em; //576px
    margin: 0 auto;
  }
 
  .tradeSectionForm {
    position: relative;
    z-index: 1;
    padding-top: var(--px24);
    padding-bottom: var(--px48);

    @media (max-width: 991px) {
      padding-top: 8.1875em; //155px - 24px
      padding-bottom: var(--px48);
    }
  }

  .tradeSectionFormTitle {
    margin-bottom: 1em;
  }

  .tradeForm {
    .withGradientBorder {
      position: relative;
      margin-bottom: var(--px24);

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 1px;
        left: 1px;
        z-index: -2;
        width: calc(100% - var(--px2));
        height: calc(100% - var(--px2));
        margin: auto;
        background-color: rgba(12, 12, 39, 0.4);
        backdrop-filter: blur(37px);
        border-radius: inherit;
      }

      .switchIcon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2.25em; //-36px
        margin: 0 auto;
      }
    }

    .selectTokenSubFieldset.tradeFromToken > .flexbox {
      z-index: 2;
    }

    .btnTradeOpenSlippage {
      transition: all 0.3s ease;

      &.isOpen {
        background: var(--gradient);
      }
    }

    .tradeFomTokenSlippageFieldsetWrapper {
      height: 100%;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease;

      &.isOpen {
        max-height: 37.5em; //600px

        .tradeFomTokenSlippageFieldset {
          opacity: 1;
        }
      }
    }

    .tradeFomTokenSlippageFieldset {
      display: block;
      margin-top: var(--px32);
      padding-top: var(--px32);
      border-bottom: 0;
      border-top: 1px solid;
      border-left: 0;
      border-right: 0;
      border-image-source: linear-gradient(92.22deg, #0BF6CC 0.58%, rgba(7, 61, 200, 0) 100%);
      border-image-slice: 1;
      opacity: 0;
      transition: opacity 0.5s ease;

      legend {
        margin-bottom: 1em;
      }

      .btnGroup {
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        margin-bottom: 1em;

        .customInputButton {
          flex: 1;

          >.btn {
            width: 100%;
            max-width: 100%;

            @media (max-width: 991px) {
              --btn-border-radius: 0.571em; //8px
              --btn-min-height: 3.428em; //48px
              font-size: var(--px14);
            }
          }
        }
      }

      .tradeCustomSlippageFormGroup {
        input {
          text-align: right;
        }
      }
    }

    .flexbox.visualiser {
      justify-content: space-between;
    }

    .tradeFormList {
      display: flex;
      flex-direction: column;
      row-gap: 1em;
      margin: 0;
      padding: var(--px12) 0 var(--px24);
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-last-child(2) {
          padding-bottom: 0.2em;
          border-bottom: var(--px1) solid;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-image-source: linear-gradient(92.22deg, #0BF6CC 0.58%, rgba(7, 61, 200, 0) 100%);
          border-image-slice: 1;
        }

        .tradeListName,
        .tradeListValue {
          color: var(--warm-grey);
          font-size: var(--px12);
          font-style: normal;
        }

        .tradeListValue {
          &.bold {
            font-weight: 900;
            color: var(--orange);

            &.red {
              color: var(--red);
            }
          }
          &.green {
            color: var(--green);
          }
        }

        .tradeListValuePreIcon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 1.25em;
          height: 1.25em;
          color: var(--orange);
        }
      }
    }

    .highFeeButton {
      border: 3px dotted var(--red);
      --btn-bg-color: rgba(11, 28, 72, 0.7);
    }

    section {
      &.highFee {
        margin-top: 1.5em;

        .highFeeText {
          font-weight: 900;
          color: var(--red);
          font-size: 1.25em;
          padding-bottom: var(--px4);
        }

        label {
          &.customSwitch {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .feeGrantSection {
    margin-bottom: .5em;
    
    .feeGrantSectionHeader {
      display: grid;
      grid-template-columns: 1fr 0.75fr;
      gap: 1em;

      p {
        margin-bottom: 0;
        font-size: var(--px18);
        font-weight: 200;
      }

      .formGroupSelect {
        min-width: 12.5em;
      }

      @media(max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--px8);
      }

      .formGroupSelect {
        width: 100%;
        max-width: 100%;
      }
    }

    .feeGrantSectionActions {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: var(--px8);
      margin-top: var(--px24);
    }

    .feeGrantSectionActionsBalance {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--px8);
      color: var(--red);
    }

    .feeGrantSectionActionsIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.5em;
      height: 1.5em;
    }
  }

  .customSelect {
    max-width: 35em;
  }

  .customSelectMinWidthTemplate {
    min-width: 25em;
  }

  .customSelectMinWidthMax {
    min-width: "100%";
  }
}
