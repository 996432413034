:global .dashboardPage {
  position: relative;
  padding: var(--px24);
  transition: opacity 0.2s ease;

  //Page border
  &:before {
    content: "";
    position: absolute;
    top: var(--px48);
    right: var(--px24);
    z-index: -9;
    width: 100%;
    max-width: calc(100% - var(--px80));
    height: calc(100% - 5em);
    max-height: calc(100% - (var(--px48) + var(--px40)));
    box-shadow: var(--box-shadow);
    border-radius: 1em;
  }

  // Radial background
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(72.72% 72.72% at 38.3% 0%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
  }

  @media(max-width: 991px) {
    padding: 1em 1em calc(var(--px80) + 6.4375em); //103px

    &:before {
      display: none;
    }
  }

  .dashboardPageWrapper {
    display: flex;
    align-items: stretch;
    height: 100%;
    min-height: calc(100vh - var(--px32) * 2 - var(--px24));
  }

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: var(--px48) var(--px24) var(--px24) var(--px48);

    @media (min-width: 992px) {
      max-width: calc(100% - var(--px80));
      transition: max-width 0.3s ease;

      &.isOpen {
        max-width: calc(100% - var(--navSidebar-max-width));
      }
    }

    @media(max-width: 991px) {
      padding: 0;
    }
  }
}
