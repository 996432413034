:global {
    .sectionHeaderUtilities {
      position: relative;
      z-index: 10;
      display: inline-block;
    }

    .defaultCursor {
      cursor: default;
    }

    .primaryButtonBackground {
      --btn-bg-color: var(--gradient) !important;
    }
  }
  