.lightbox :global(.yarl__button) {
  border-radius: var(--btn-border-radius);
  background-color: rgba(17, 17, 48, 0.6);
  transition: all 0.3s ease;
}
.lightbox :global(.yarl__button.yarl__navigation_next),
.lightbox :global(.yarl__button.yarl__navigation_prev) {
  background-color: transparent;
}
.lightbox :global(.yarl__thumbnails_thumbnail) {
  position: relative;
  padding: var(--px12);
  border-radius: 1em;
  border: 0;
  z-index: 0;
}
.lightbox :global(.yarl__thumbnails_thumbnail_active) {
  padding: var(--px24);
}
.lightbox :global(.yarl__thumbnails_thumbnail:before) {
  border: none;
}

.lightbox :global(.yarl__thumbnails_thumbnail_active:before) {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: var(--px1) solid transparent;
    background: var(--dark-blue-gradient) border-box;
    border-radius: inherit;
    -webkit-mask: linear-gradient(to right, black 0, black 100%) content-box, linear-gradient(to right, black 0, black 100%);
    mask: linear-gradient(to right, black 0, black 100%) content-box, linear-gradient(to right, black 0, black 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

:global .help {

  &.helpContentTable {
    &:after {
      background: url("../assets/images/trade-bg.webp") no-repeat top left fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
      background-size: 100% auto, cover;

      @media (max-width: 991px) {
        background: url("../assets/images/trade-bg.webp") no-repeat top left fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(69.79% 69.79% at 53.16% 2.31%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center fixed;
        background-size: 100% auto, cover;
      }

      @media (max-width: 575px) {
        background: url("../assets/images/trade-bg-mobile-2x.webp") no-repeat top center fixed, url("../assets/images/white-noise-x1.webp") no-repeat top center fixed, radial-gradient(118.8% 32.05% at 23.07% 0%, rgba(92, 92, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) no-repeat center;
        background-size: 100% auto, cover;
      }
    }
  }

  .helpPageWrapper {
    padding: var(--px24) 0 var(--px48);
  }

  .sectionWrapperSm {
    max-width: 36em; //576px
  }

  .sectionWrapperMd {
    max-width: 50em; //800px
  }

  .sectionWrapperLg {
    max-width: 71.25em; //1140px
  }

  &.helpWallet .helpSectionHeader img.logo {
    width: 100%;
    max-width: 12.9375em;
    height: auto;
    margin: 0 0 1em;
  }

  .helpSectionHeader {
    margin-bottom: var(--px48);

    &.withGradientBorderBottom {
      padding-bottom: var(--px48);
    }

    >p {
       color: var(--warm-grey);

      &:only-child,
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sectionWrapperSm,
  .sectionWrapperMd,
  .sectionWrapperLg{
    width: 100%;
    margin: 0 auto;
  }

  .helpContentTableSubSection {
    &:not(:first-child):not(:only-child) {
      margin-top: var(--px32);
    }
  }

  .helpContentTableSubSectionTitle {
    font-weight: 200;
  }

  .helpContentTableList {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      width: 100%;
      max-width: 100%;
      margin-bottom: var(--px24);
      padding-bottom: var(--px24);
      border-bottom: var(--border);

      &:last-child,
      &:only-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .flexbox {
        justify-content: space-between;
        margin-bottom: var(--px12);
      }

      .helpContentTableSubList {
        margin: 0;
        padding: 0;
        list-style: none;

        .iconWrapper {
          display: inline-flex;
          align-items: center;
          vertical-align: middle;
        }

        .isIcon {
          display: inline;
          vertical-align: middle;
          max-width: 1em;
          max-height: 1em;
          border-radius: 0;
          border: 0;
          padding: 0;
          line-height: 1;
          object-fit: contain;
        }

        .GRVT8 {
          transform: scale(0.75);
          margin-top: calc(var(--px1) * -1);
        }

        >li > a {
          font-weight: 500;
        }

        >li > ul {
          margin: 0;
          padding-left: 1em;
          list-style: none;

          >li a {
            font-weight: 200;
          }
        }
      }
    }
  }

  .helpSubPageGrid {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: var(--px48);

    .helpSubPageSidebar {
      position: relative;
    }

    .helpSubPageSidebarWrapper {
      position: sticky;
      top: var(--px24);
      left: 0;
      padding: 1em;
      border: var(--border);
      border-radius: 1em;

      .buttonContainer {
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: var(--border);
      }

      > ul {
        margin: 0;
        padding: 0;
        list-style: none;

        >li > a {
          font-weight: 500;
        }

        .iconWrapper {
          display: inline-flex;
          align-items: center;
          vertical-align: middle;
        }

        .isIcon {
          display: inline;
          vertical-align: middle;
          max-width: 1em;
          max-height: 1em;
          border-radius: 0;
          border: 0;
          padding: 0;
          line-height: 1;
          object-fit: contain;
        }

        .GRVT8 {
          transform: scale(0.75);
          margin-top: calc(var(--px1) * -1);
        }

        ul {
          margin: 0;
          padding-left: 1em;
          list-style: none;

          >li a {
            font-weight: 200;
          }
        }
      }
    }

    @media (max-width: 991px)  {
      display: flex;
      flex-direction: column;
      gap: var(--px24);
    }
  }

  .helpSubPageSection {
    margin-bottom: var(--px48);

    &.withGradientBorderTop {
      padding-top: var(--px48);
    }

    strong {
      font-weight: 900;
    }

    p {
      font-weight: 200;
    }

    .iconWrapper {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
    }

    img {
      display: inline-block;
      width: 100%;
      max-width: 100%;
      height: auto;
      padding: 0.5em;
      border: var(--border);
      border-radius: 1em;
      font-size: 1em;

      &.isIcon {
        display: inline;
        vertical-align: middle;
        max-width: 1em;
        max-height: 1em;
        border-radius: 0;
        border: 0;
        padding: 0;
        line-height: 1;
        object-fit: contain;
      }

      // TODO: Francesca. this quickfix handles vertical alignment, but can be improved
      &.GRVT8 {
          margin-top: -2px;

        &.title{
          transform: scale(1.4);
          margin-top: calc(var(--px1) * -1);
          margin-left: var(--px4);
        }
      }

      &.sm {
        max-width: var(--px64);
        margin: var(--px12) 0;
      }

      &.md {
        max-width: 60%;
      }

      @media (min-width: 1150px) {
        &.md80 {
          max-width: 80%;
        }
      }
      &.icon {
        max-width: 6em;
      }

      &.hasMargin {
        margin: 1em 0;
      }
    }

    .addToWalletHelpTokenItem {
      cursor: pointer;
      max-width: fit-content;
      max-width: -moz-fit-content;
    }
  }

  .tokenGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .lightboxImageOpener:hover {
    cursor: pointer;
  }

}
