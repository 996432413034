fieldset {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;

  &.borderBottom {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: var(--border);
  }
}

legend {
  display: block;
  float: left !important;
  width: 100%;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.label,
label:not(.btn) {
  display: block;
  width: 100%;
  color: var(--warm-grey);
  font-size: var(--px14);
  margin-bottom: 8px;
}

.formGroupInline{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  >.btn {
    flex-shrink: 0;
    position: relative;
    z-index: 0;
  }

  .formGroup {
    flex: 1;
  }
}

.formGroup {
  &.gradientText {
    > input:not([type="radio"]):not([type="checkbox"]):not(.customSelect__input) {
      height: unset;
      padding: 0;
      background-image: linear-gradient(92.22deg, #0BF6CC 0.58%, #073DC8 100%);
      border: 0;
      border-radius: 0.266em; //8px
      font-size: var(--px30);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &:focus {
        box-shadow: 0 0 0.033em 0.1em rgba(255, 255, 255, 0.2);
      }

      &:disabled,
      &:read-only {
        color: white;
        opacity: 1;
      }
    }
  }

  &.formGroupCheck {
    label {
      display: inline-flex;
      align-items: center;
      gap: var(--px8);
    }
  }

  &.formGroupSwitch {

    .formGroupSwitchWrapper {
      display: flex;
      align-items: center;
      gap: var(--px8);
    }

    .label {
      color: inherit;
      margin-bottom: 0;
      font-size: unset;
    }

    .customSwitch {
      position: relative;
      display: block;
      width: 4em; //48px
      height: 1.75em; //28px

      >input {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
      }

      input:checked {
        ~ .switch {
          background: var(--gradient);
          &:before {
            transform: translate(1.8em, -50%);
            background-color: white;
          }
        }
      }

      .switch {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: var(--transparent-white);
        border-radius: 2em;
        transition: background 0.3s ease;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0.15em;
          width: 1.5em; //24px
          height: 1.5em;
          background-color: var(--warm-grey);
          border-radius: 100%;
          transform: translate(0, -50%);
          transition: all 0.3s ease;
        }
      }
    }
  }
}

input:not([type="radio"]):not([type="checkbox"]):not(.customSelect__input),
input:not([type="radio"]):not([type="checkbox"]):not(.customSelect__input):-webkit-autofill,
input:not([type="radio"]):not([type="checkbox"]):not(.customSelect__input):-webkit-autofill:hover,
input:not([type="radio"]):not([type="checkbox"]):not(.customSelect__input):-webkit-autofill:focus,
textarea,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  display: block;
  width: 100%;
  max-width: 100%;
  height: var(--px48);
  padding: var(--px8) 1em;
  background-color: var(--dark-medium);
  border: var(--px1) solid var(--dark-medium);
  border-radius: var(--px8);
  color: inherit;
  // must use this so text is visible when autocomplete is hovered over,
  // even though this overrides the placeholder default gray text color
  -webkit-text-fill-color: var(--body-font-color);
  transition: background-color 5000s ease-in-out 0s;

  &:focus {
    outline: 0;
    box-shadow: inset var(--focus);
  }

  &:disabled,
  &:read-only {
    color: var(--warm-grey);
  }
}

input[type="checkbox"],
input[type="radio"] {
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 1.25em;
  height: 1.25em;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  box-shadow: inset 0 0 0 1px white;
  appearance: none;
  user-select: none;
  transition: all 0.3s ease;

  &:checked {
    border: 0;
    box-shadow: none;

    &:focus {
      box-shadow: var(--focus);
    }
  }

  &:focus {
    box-shadow: inset 0 0 0 1px white, var(--focus);
  }

  &.buttonInput {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
  }
}

input[type="checkbox"] {
  border-radius: 0.15em;

  &:checked {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"), var(--gradient);
  }
}

input[type="radio"] {
  border-radius: 100%;

  &:checked {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"), var(--gradient);
  }
}

.helpText {
  margin-top: var(--px8);
  font-size: var(--px12);
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: var(--px8);
  width: 100%;
  max-width: 100%;
  border-radius: var(--px8);

  .inputGroupIcon {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    color: var(--warm-grey);
  }

  >div, >input {
    flex: 1;
  }

  &.hasIcon {
    position: relative;
    padding: 0 1em;
    background-color: var(--dark-medium);

    &:focus-within {
      box-shadow: var(--focus);
    }

    .customSelect {
      position: static;
      overflow: hidden;
    }

    .customSelect__control {
      position: static;
      min-height: var(--px48);
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;

      &.customSelect__control--is-focused {
        box-shadow: none;
      }
    }
  }
}
